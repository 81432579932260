import axios from 'axios';

import { BaseQuery } from './';
import { Answer } from '../components';
import { trackEvent } from '../ga';

export interface AnswerResponse<Q, A extends Answer> {
    query: Q;
    answer: A;
}

export type BaseRequest<A extends Answer> = (
    query: BaseQuery
) => Promise<AnswerResponse<BaseQuery, A>>;

// pass image data to backend api and get back an A
export function baseRequestFrom<A extends Answer>(
    query: BaseQuery,
    url: string
): Promise<AnswerResponse<BaseQuery, A>> {
    const data = query.toFormData();
    const config = {
        headers: { 'content-type': 'multipart/form-data' },
    };

    // log api event
    trackEvent('API', `Model: ${url}`, JSON.stringify(query.toJson()));

    return axios
        .post(url, data, config)
        .then((resp) => resp.data)
        .catch((err) => {
            if (err.response.status === 413) {
                // eslint-disable-next-line no-throw-literal
                throw {
                    response: {
                        data: {
                            error: 'File too large. The data or image must be smaller than 1MB.',
                        },
                    },
                };
            }
        });
}
