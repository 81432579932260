import * as React from 'react';
import { Typography, Table, Collapse } from '@allenai/varnish';
import { BasicFilterDropdown, FilterIcon, ColumnProps } from '@allenai/varnish/es/table';
import { SortOrder } from '@allenai/varnish/es/table/interface';

import { Dictionary, strIncludesCaseInsensitive } from '../utils';
import { SituationRecognitionAnswer, Situation } from '../api';
import {
    AnswerInfoProps,
    ImageArea,
    MediumImage,
    KeyValue,
    SparkEnvelope,
    Spark,
    SparkValue,
    PropertiesArea,
} from '.';

const { BodySmallBold, BodySmall } = Typography;

type Props = AnswerInfoProps<SituationRecognitionAnswer>;
type SituationItem = KeyValue<Situation>;

export class SituationRecognitionAnswerInfo extends React.Component<Props> {
    pageSize = 6;

    columns: ColumnProps<SituationItem>[] = [
        {
            title: 'Score',
            dataIndex: ['value', 'score'],
            key: 'value',
            render: (val: number) => (
                <div title={val.toString()}>
                    <SparkEnvelope>
                        <Spark value={val} />
                    </SparkEnvelope>{' '}
                    <SparkValue>{`${(100 * val).toFixed(1)}%`}</SparkValue>
                </div>
            ),
            sorter: (a: SituationItem, b: SituationItem) => a.value.score - b.value.score,
            sortDirections: ['descend' as SortOrder, 'ascend' as SortOrder],
            defaultSortOrder: 'descend' as SortOrder,
        },
        {
            title: 'Verb',
            dataIndex: 'key',
            key: 'key',
            sorter: (a: SituationItem, b: SituationItem) => (a.key < b.key ? -1 : 1),
            sortDirections: ['descend' as SortOrder, 'ascend' as SortOrder],
            filterDropdown: BasicFilterDropdown,
            filterIcon: FilterIcon,
            onFilter: (filter, record) => strIncludesCaseInsensitive(filter, record.key),
        },
        {
            title: 'Roles',
            dataIndex: ['value', 'fields'],
            key: 'value.fields',
            render: (fields: Dictionary<string>) => (
                <div>
                    {Object.keys(fields).map((k) =>
                        fields[k] ? (
                            <div key={k}>
                                <BodySmallBold>{k}:</BodySmallBold>{' '}
                                <BodySmall>{fields[k]}</BodySmall>
                            </div>
                        ) : null
                    )}
                </div>
            ),
            filterDropdown: BasicFilterDropdown,
            filterIcon: FilterIcon,
            onFilter: (filter, record) => {
                return (
                    Object.values(record.value.fields).filter((f) =>
                        f.toLowerCase().includes(filter.toString().toLowerCase())
                    ).length > 0
                );
            },
        },
    ];

    render() {
        const data: SituationItem[] = this.props.answer
            ? Object.keys(this.props.answer.situations).map((k) => {
                  return {
                      key: k,
                      value: this.props.answer
                          ? this.props.answer.situations[k]
                          : ({} as Situation),
                  };
              })
            : [];

        return (
            <PropertiesArea>
                {this.props.originalImgSrc ? (
                    <Collapse.Panel header="Image" key="image">
                        <ImageArea>
                            <MediumImage src={this.props.originalImgSrc} />
                        </ImageArea>
                    </Collapse.Panel>
                ) : null}
                {this.props.answer ? (
                    <Collapse.Panel header="Predicted Situations" key="table1">
                        <Table
                            size="small"
                            scroll={{ x: true }}
                            rowKey={(record) => `${record.key}_${record.value}`}
                            columns={this.columns}
                            dataSource={data}
                            pagination={
                                data.length > this.pageSize && {
                                    pageSize: this.pageSize,
                                    simple: true,
                                }
                            }
                        />
                    </Collapse.Panel>
                ) : null}
            </PropertiesArea>
        );
    }
}
