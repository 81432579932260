import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Tooltip, Collapse } from '@allenai/varnish';
import { RGB } from '@allenai/varnish/es/varnish/colors';

import { DepthEstimationAnswer } from '../api';
import { CanvasImage } from './CanvasImage';
import { AnswerInfoProps, ImageArea, PropertiesArea, FullWidthSlider } from '.';
import { turboInterpolateOrClip, Animator, Vector } from '../utils';

import turboSrc from '../icons/turbo.jpg';

interface State {
    overlayOpacity: number;
    hoverLabel?: number;
    minDepth: number;
    maxDepth: number;
}

type Props = AnswerInfoProps<DepthEstimationAnswer>;

export class DepthEstimationAnswerInfo extends React.Component<Props, State> {
    maxVectorOverlayOpacity = 1; // todo: remove this and its uses if we stick with 1
    animator: Animator = new Animator({
        startValue: 0,
        endValue: 0.7,
        duration: 1200,
        onChange: (value: number) => {
            this.setState({ overlayOpacity: value });
        },
    });

    constructor(props: Props) {
        super(props);

        this.state = {
            overlayOpacity: 0,
            minDepth: 0,
            maxDepth: 1,
        };
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.answer !== prevProps.answer) {
            this.update();
        }
    }

    update() {
        if (this.props.answer) {
            const vals = ([] as number[]).concat(...this.props.answer.depth);
            let minDepth = 999999999;
            let maxDepth = 0.01;
            vals.forEach((v) => {
                if (v < minDepth) {
                    minDepth = v;
                }
                if (v > maxDepth) {
                    maxDepth = v;
                }
            });

            this.setState({
                minDepth,
                maxDepth,
            });
            this.animator.start();
        }
    }

    canvasSpaceToImageSpace(v: number, scale: number) {
        return Math.floor(v / (scale > 0 ? scale : 1));
    }

    // convert image to grayscale, then apply the segment color on top based on animated opacity
    drawAnswer = (
        ctx: CanvasRenderingContext2D,
        scale: number,
        depthEstimationAnswer?: DepthEstimationAnswer
    ) => {
        if (depthEstimationAnswer && depthEstimationAnswer.depth.length) {
            const imageData = ctx.getImageData(
                0,
                0,
                ctx.canvas.clientWidth,
                ctx.canvas.clientHeight
            );
            for (let j = 0; j < imageData.width; j++) {
                for (let i = 0; i < imageData.height; i++) {
                    const scaledX = this.canvasSpaceToImageSpace(j, scale);
                    const scaledY = this.canvasSpaceToImageSpace(i, scale);
                    const val = depthEstimationAnswer.depth[scaledX][scaledY];
                    const color = this.getDepthColor(this.getDepthPercent(val));

                    const index = i * 4 * imageData.width + j * 4;
                    const red = imageData.data[index];
                    const green = imageData.data[index + 1];
                    const blue = imageData.data[index + 2];
                    const average = ((red + green + blue) / 3) * (1 - this.maxVectorOverlayOpacity);
                    // transitioning from color with no depth overlay
                    // to grayscale with overlay
                    imageData.data[index] =
                        red * (1 - this.state.overlayOpacity) +
                        this.state.overlayOpacity *
                            (average + color.r * this.maxVectorOverlayOpacity);
                    imageData.data[index + 1] =
                        green * (1 - this.state.overlayOpacity) +
                        this.state.overlayOpacity *
                            (average + color.g * this.maxVectorOverlayOpacity);
                    imageData.data[index + 2] =
                        blue * (1 - this.state.overlayOpacity) +
                        this.state.overlayOpacity *
                            (average + color.b * this.maxVectorOverlayOpacity);
                }
            }
            ctx.putImageData(imageData, 0, 0);
        }
    };

    // on mouse move, display a tooltip at the bottom saying what is hovered on
    // we can do something other than a tooltip in the future
    onMouseMove = (evt: any, canvas: HTMLCanvasElement, padding: number, scale: number) => {
        if (canvas && this.props.answer && this.props.answer.depth.length) {
            const rect = canvas.getBoundingClientRect();
            const x = this.canvasSpaceToImageSpace(evt.clientX - rect.left, scale);
            const y = this.canvasSpaceToImageSpace(evt.clientY - rect.top, scale);
            const val = this.props.answer.depth[x][y];
            this.setState({ hoverLabel: val });
        }
    };

    getDepthPercent = (depth: number) => {
        const { minDepth, maxDepth } = this.state;
        const depthPercent = (depth - minDepth) / (maxDepth - minDepth);
        return depthPercent;
    };

    getDepthColor = (depth: number) => {
        depth = 1 - depth;
        const color: Vector = turboInterpolateOrClip(depth);
        return new RGB(color[0] * 255, color[1] * 255, color[2] * 255);
    };

    handelOpacitySliderChange = (value: any) => {
        this.setState({
            overlayOpacity: value,
        });
    };

    render() {
        return (
            <PropertiesArea>
                {this.props.originalImgSrc ? (
                    <Collapse.Panel header="Depth" key="image">
                        <React.Fragment>
                            <WithStyledTooltip
                                color={
                                    this.state.hoverLabel
                                        ? this.getDepthColor(
                                              this.getDepthPercent(this.state.hoverLabel)
                                          ).toString()
                                        : undefined
                                }
                            />
                            <Tooltip
                                title={
                                    this.state.hoverLabel
                                        ? `Distance: ${this.state.hoverLabel.toFixed(1)} meters`
                                        : undefined
                                }
                                placement="bottomLeft"
                                overlayClassName="overlay">
                                <ImageArea>
                                    <CanvasImage
                                        src={this.props.originalImgSrc}
                                        drawPropHash={`${this.state.overlayOpacity}`}
                                        onMouseMove={this.onMouseMove}
                                        draw={
                                            this.props.answer
                                                ? (
                                                      ctx: CanvasRenderingContext2D,
                                                      padding: number,
                                                      scale: number
                                                  ) =>
                                                      this.drawAnswer(ctx, scale, this.props.answer)
                                                : undefined
                                        }
                                    />
                                </ImageArea>
                            </Tooltip>
                        </React.Fragment>
                    </Collapse.Panel>
                ) : null}
                {this.props.answer ? (
                    <Collapse.Panel header="Image Key" key="key">
                        <KeyGrid>
                            {`${this.state.minDepth.toFixed(1)}m`}
                            <img src={turboSrc} alt="Turbo Colors" />
                            {`${this.state.maxDepth.toFixed(1)}m`}
                        </KeyGrid>
                    </Collapse.Panel>
                ) : null}
                {this.props.answer ? (
                    <Collapse.Panel header="Depth Opacity" key="opacity">
                        <FullWidthSlider
                            min={0}
                            max={1}
                            step={0.02}
                            value={this.state.overlayOpacity}
                            onChange={this.handelOpacitySliderChange}
                        />
                    </Collapse.Panel>
                ) : null}
            </PropertiesArea>
        );
    }
}

const KeyGrid = styled.div`
    display: grid;
    grid-template-columns: min-content auto min-content;
    grid-gap: ${({ theme }) => `${theme.spacing.xs}`};
    align-items: center;

    img {
        width: 100%;
        height: 20px;
        transform: scaleX(-1);
    }
`;

const WithStyledTooltip = createGlobalStyle<{ color?: string }>`
    .overlay {
        .ant-tooltip-content {
            .ant-tooltip-inner {
                color: black;
                border: ${({ color }) => `solid 2px ${color}`};
                background: white;
                border-radius: 4px;
                min-width: 270px;
            }

            .ant-tooltip-arrow {
                border-left-color: ${({ color }) => color};
                border-top-color: ${({ color }) => color};
            }
        }
    }
`;
