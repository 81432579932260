import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Tooltip, Collapse } from '@allenai/varnish';
import { RGB } from '@allenai/varnish/es/varnish/colors';

import { SurfaceNormalEstimationAnswer } from '../api';
import { CanvasImage } from './CanvasImage';
import { AnswerInfoProps, ImageArea, PropertiesArea, FullWidthSlider } from '.';
import { Animator, Vector } from '../utils';

import xyzSrc from '../icons/xyz.jpg';

interface State {
    overlayOpacity: number;
    hoverLabel?: Vector;
}

type Props = AnswerInfoProps<SurfaceNormalEstimationAnswer>;

export class SurfaceNormalEstimationAnswerInfo extends React.Component<Props, State> {
    maxVectorOverlayOpacity = 1; // todo: remove this and its uses if we stick with 1
    animator: Animator = new Animator({
        startValue: 0,
        endValue: 0.85,
        duration: 1200,
        onChange: (value: number) => {
            this.setState({ overlayOpacity: value });
        },
    });

    constructor(props: Props) {
        super(props);

        this.state = {
            overlayOpacity: 0,
        };
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.answer !== prevProps.answer) {
            this.update();
        }
    }

    update() {
        if (this.props.answer) {
            this.animator.start();
        }
    }

    canvasSpaceToImageSpace(v: number, scale: number) {
        return Math.floor(v / (scale > 0 ? scale : 1));
    }

    // convert image to grayscale, then apply the segment color on top based on animated opacity
    drawAnswer = (
        ctx: CanvasRenderingContext2D,
        scale: number,
        surfaceNormalEstimationAnswer?: SurfaceNormalEstimationAnswer
    ) => {
        if (surfaceNormalEstimationAnswer && surfaceNormalEstimationAnswer.normals.length) {
            const imageData = ctx.getImageData(
                0,
                0,
                ctx.canvas.clientWidth,
                ctx.canvas.clientHeight
            );
            for (let j = 0; j < imageData.width; j++) {
                for (let i = 0; i < imageData.height; i++) {
                    const scaledX = this.canvasSpaceToImageSpace(j, scale);
                    const scaledY = this.canvasSpaceToImageSpace(i, scale);
                    const val = surfaceNormalEstimationAnswer.normals[scaledX][scaledY];

                    const index = i * 4 * imageData.width + j * 4;
                    const red = imageData.data[index];
                    const green = imageData.data[index + 1];
                    const blue = imageData.data[index + 2];
                    const average = ((red + green + blue) / 3) * (1 - this.maxVectorOverlayOpacity);
                    // transitioning from color with no normals overlay
                    // to grayscale with overlay
                    imageData.data[index] =
                        red * (1 - this.state.overlayOpacity) +
                        this.state.overlayOpacity *
                            (average + 255 * val[0] * this.maxVectorOverlayOpacity);
                    imageData.data[index + 1] =
                        green * (1 - this.state.overlayOpacity) +
                        this.state.overlayOpacity *
                            (average + 255 * val[1] * this.maxVectorOverlayOpacity);
                    imageData.data[index + 2] =
                        blue * (1 - this.state.overlayOpacity) +
                        this.state.overlayOpacity *
                            (average + 255 * val[2] * this.maxVectorOverlayOpacity);
                }
            }
            ctx.putImageData(imageData, 0, 0);
        }
    };

    // on mouse move, display a tooltip at the bottom saying what is hovered on
    // we can do something other than a tooltip in the future
    onMouseMove = (evt: any, canvas: HTMLCanvasElement, padding: number, scale: number) => {
        if (canvas && this.props.answer && this.props.answer.normals.length) {
            const rect = canvas.getBoundingClientRect();
            const x = this.canvasSpaceToImageSpace(evt.clientX - rect.left, scale);
            const y = this.canvasSpaceToImageSpace(evt.clientY - rect.top, scale);
            const val = this.props.answer.normals[x][y];
            this.setState({ hoverLabel: val });
        }
    };

    getNormalColor = (normal: Vector) => {
        return new RGB(normal[0] * 255, normal[1] * 255, normal[2] * 255);
    };

    handelOpacitySliderChange = (value: any) => {
        this.setState({
            overlayOpacity: value,
        });
    };

    render() {
        return (
            <PropertiesArea>
                {this.props.originalImgSrc ? (
                    <Collapse.Panel header="Surface Normals" key="image">
                        <React.Fragment>
                            <WithStyledTooltip
                                color={
                                    this.state.hoverLabel
                                        ? this.getNormalColor(this.state.hoverLabel).toString()
                                        : undefined
                                }
                            />
                            <Tooltip
                                title={
                                    this.state.hoverLabel
                                        ? `X: ${this.state.hoverLabel[0].toFixed(
                                              2
                                          )}, Y: ${this.state.hoverLabel[1].toFixed(
                                              2
                                          )}, Z: ${this.state.hoverLabel[2].toFixed(2)}`
                                        : undefined
                                }
                                placement="bottomLeft"
                                overlayClassName="overlay">
                                <ImageArea>
                                    <CanvasImage
                                        src={this.props.originalImgSrc}
                                        drawPropHash={`${this.state.overlayOpacity}`}
                                        onMouseMove={this.onMouseMove}
                                        draw={
                                            this.props.answer
                                                ? (
                                                      ctx: CanvasRenderingContext2D,
                                                      padding: number,
                                                      scale: number
                                                  ) =>
                                                      this.drawAnswer(ctx, scale, this.props.answer)
                                                : undefined
                                        }
                                    />
                                </ImageArea>
                            </Tooltip>
                        </React.Fragment>
                    </Collapse.Panel>
                ) : null}
                {this.props.answer ? (
                    <Collapse.Panel header="Image Key" key="key">
                        <KeyGrid>
                            <img src={xyzSrc} alt="Color Axis" />
                            <div>
                                Each channel in the bitmap corresponds to a spatial dimension: X
                                (red), Y (green) and Z (blue).
                            </div>
                        </KeyGrid>
                    </Collapse.Panel>
                ) : null}
                {this.props.answer ? (
                    <Collapse.Panel header="Normals Opacity" key="opacity">
                        <FullWidthSlider
                            min={0}
                            max={1}
                            step={0.02}
                            value={this.state.overlayOpacity}
                            onChange={this.handelOpacitySliderChange}
                        />
                    </Collapse.Panel>
                ) : null}
            </PropertiesArea>
        );
    }
}

const WithStyledTooltip = createGlobalStyle<{ color?: string }>`
    .overlay {
        .ant-tooltip-content {
            .ant-tooltip-inner {
                color: black;
                border: ${({ color }) => `solid 2px ${color}`};
                background: white;
                border-radius: 4px;
                min-width: 270px;
            }

            .ant-tooltip-arrow {
                border-left-color: ${({ color }) => color};
                border-top-color: ${({ color }) => color};
            }
        }
    }
`;

const KeyGrid = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: ${({ theme }) => `${theme.spacing.xs}`};
    align-items: center;

    img {
        width: 100%;
        object-fit: contain;
        max-height: 85px;
    }
`;
