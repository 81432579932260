import * as React from 'react';
import { Input } from '@allenai/varnish';

import { ModelParamControlProps, ModelParamLabel, BaseModelParams } from './ModelParams';

export interface ImageGenerationParams extends BaseModelParams {
    caption?: string;
}

type Props = ModelParamControlProps<ImageGenerationParams>;

export class ImageGenerationModelParamControl extends React.PureComponent<
    Props,
    ImageGenerationParams
> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    static getDerivedStateFromProps(props: Props, state: ImageGenerationParams) {
        if (props.modelParams && props.modelParams.caption !== state.caption) {
            return { caption: props.modelParams.caption };
        }
        return null;
    }

    onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { modelParams } = this.props;
        modelParams.caption = event.target.value;
        this.setState({ caption: modelParams.caption }, () => this.props.onChange(modelParams));
    };

    render() {
        return (
            <div>
                {this.props.modelParamComponentUiParams.getInputLabel('caption') ? (
                    this.props.modelParamComponentUiParams.getInputLabel('caption')
                ) : (
                    <ModelParamLabel>Caption:</ModelParamLabel>
                )}
                <Input.TextArea
                    disabled={this.props.disabled}
                    onChange={this.onChange}
                    value={this.state.caption}
                />
            </div>
        );
    }
}
