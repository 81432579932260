/**
 * This file is meant for shared display components that you use throughout
 * your application.
 *
 * Components with a lot of logic, or those that are particularly complicated
 * should probably be put in their own file. This file is meant for the
 * re-usable, simple things used in a lot of different spots in your UI.
 */
import * as React from 'react';
import styled from 'styled-components';
import { Slider, Collapse } from '@allenai/varnish';
import { WarningOutlined, LoadingOutlined } from '@ant-design/icons';

export const Loading = styled(LoadingOutlined)`
    font-size: ${({ theme }) => theme.typography.bodyJumbo.fontSize};
    z-index: 1;
`;

export const Warning = styled(WarningOutlined)`
    font-size: ${({ theme }) => theme.typography.bodyJumbo.fontSize};
`;

export const Error: React.SFC<{ message: string }> = ({ message }) => (
    <ErrorGrid>
        <Warning />
        {message}
    </ErrorGrid>
);

const ErrorGrid = styled.div`
    display: grid;
    grid-template-columns: min-content max-content;
    grid-gap: ${({ theme }) => `${theme.spacing.xs}`};
    align-items: center;
    color: ${({ theme }) => theme.palette.text.error.hex};
    z-index: 1;
`;

export const SparkEnvelope = styled.div`
    width: ${({ theme }) => theme.spacing.xl2};
    height: ${({ theme }) => theme.spacing.md};
    background: ${({ theme }) => theme.palette.background.info};
    margin: ${({ theme }) => `${theme.spacing.xxs} 0`};
    display: inline-block;
`;

export const Spark = styled.div<{ value: number }>`
    background: ${({ theme }) => theme.palette.primary.light};
    width: ${({ value }) => `${100 * value}%`};
    height: 100%;
`;

export const SparkValue = styled.div`
    display: inline-block;
    vertical-align: top;
`;

export const PropertiesArea = styled(Collapse).attrs({
    bordered: false,
    defaultActiveKey: ['image', 'key', 'opacity', 'confidence', 'table1'],
})`
    margin-top: ${({ theme }) => theme.spacing.xs};
`;

export const FullWidthSlider = styled(Slider)`
    margin: ${({ theme }) => `0 ${theme.spacing.xs} ${theme.spacing.xl} 0`};
`;

export const ColorContainer = styled.div<{ color: string }>`
    height: 30px;
    width: 30px;
    background: ${({ color }) => color};
`;
