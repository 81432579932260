import * as React from 'react';
import { Table, Collapse } from '@allenai/varnish';
import { BasicFilterDropdown, FilterIcon, ColumnProps } from '@allenai/varnish/es/table';
import { SortOrder } from '@allenai/varnish/es/table/interface';

import { strIncludesCaseInsensitive } from '../utils';
import { ClassificationAnswer } from '../api';
import {
    AnswerInfoProps,
    ImageArea,
    MediumImage,
    KeyValue,
    SparkEnvelope,
    Spark,
    SparkValue,
    PropertiesArea,
} from '.';

type Props = AnswerInfoProps<ClassificationAnswer>;
type LabelItem = KeyValue<number>;

export class ClassificationAnswerInfo extends React.Component<Props> {
    pageSize = 6;

    columns: ColumnProps<LabelItem>[] = [
        {
            title: 'Score',
            dataIndex: 'value',
            key: 'value',
            render: (val: number) => (
                <div title={val.toString()}>
                    <SparkEnvelope>
                        <Spark value={val} />
                    </SparkEnvelope>{' '}
                    <SparkValue>{`${(100 * val).toFixed(1)}%`}</SparkValue>
                </div>
            ),
            sorter: (a: LabelItem, b: LabelItem) => a.value - b.value,
            sortDirections: ['descend' as SortOrder, 'ascend' as SortOrder],
            defaultSortOrder: 'descend' as SortOrder,
        },
        {
            title: 'Label',
            dataIndex: 'key',
            key: 'key',
            sorter: (a: LabelItem, b: LabelItem) => (a.key < b.key ? -1 : 1),
            sortDirections: ['descend' as SortOrder, 'ascend' as SortOrder],
            filterDropdown: BasicFilterDropdown,
            filterIcon: FilterIcon,
            onFilter: (filter, record) => strIncludesCaseInsensitive(filter, record.key),
        },
    ];

    render() {
        const data: LabelItem[] = this.props.answer
            ? Object.keys(this.props.answer.labels).map((k) => {
                  return { key: k, value: this.props.answer ? this.props.answer.labels[k] : 0 };
              })
            : [];

        return (
            <PropertiesArea>
                {this.props.originalImgSrc ? (
                    <Collapse.Panel header="Image" key="image">
                        <ImageArea>
                            <MediumImage src={this.props.originalImgSrc} />
                        </ImageArea>
                    </Collapse.Panel>
                ) : null}
                {this.props.answer ? (
                    <Collapse.Panel header="Predicted Labels" key="table1">
                        <Table
                            size="small"
                            rowKey={(record) => `${record.key}_${record.value}`}
                            columns={this.columns}
                            dataSource={data}
                            pagination={
                                data.length > this.pageSize && {
                                    pageSize: this.pageSize,
                                    simple: true,
                                }
                            }
                        />
                    </Collapse.Panel>
                ) : null}
            </PropertiesArea>
        );
    }
}
