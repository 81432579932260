import * as React from 'react';
import styled from 'styled-components';
import { Table, Collapse } from '@allenai/varnish';
import { BasicFilterDropdown, FilterIcon, ColumnProps } from '@allenai/varnish/es/table';
import { SortOrder } from '@allenai/varnish/es/table/interface';

import {
    PoseEstimationAnswer,
    convertPersonToDrawable,
    DrawableLine,
    getAnnotatedPoints,
    getLines,
    AnnotatedDrawablePoint,
} from '../api';
import { CanvasImage } from './CanvasImage';
import {
    AnswerInfoProps,
    ImageArea,
    SparkEnvelope,
    Spark,
    SparkValue,
    PropertiesArea,
    FullWidthSlider,
} from '.';
import { Animator, toNumberString, Dictionary, strIncludesCaseInsensitive } from '../utils';
import { ColorContainer } from './shared';

import astroSrc from '../icons/astro.jpg';

interface State {
    points: AnnotatedDrawablePoint[];
    filteredPoints: AnnotatedDrawablePoint[];
    lines: DrawableLine[];
    filteredLines: DrawableLine[];
    sliderVal: number;
    marks: {};
    overlayOpacity: number;
}

type Props = AnswerInfoProps<PoseEstimationAnswer>;

export class PoseEstimationAnswerInfo extends React.Component<Props, State> {
    lineWidth = 5;
    animator: Animator = new Animator({
        startValue: 0,
        endValue: 0.7,
        duration: 1200,
        onChange: (value: number) => {
            this.setState({ overlayOpacity: value });
        },
    });

    pageSize = 6;

    columns: ColumnProps<AnnotatedDrawablePoint>[] = [
        {
            title: 'Confidence',
            dataIndex: 'confidence',
            key: 'confidence',
            render: (val: number) => (
                <div title={val.toString()}>
                    <SparkEnvelope>
                        <Spark value={val} />
                    </SparkEnvelope>{' '}
                    <SparkValue>{`${(100 * val).toFixed(1)}%`}</SparkValue>
                </div>
            ),
            sorter: (a: AnnotatedDrawablePoint, b: AnnotatedDrawablePoint) =>
                a.confidence - b.confidence,
            sortDirections: ['descend' as SortOrder, 'ascend' as SortOrder],
            defaultSortOrder: 'descend' as SortOrder,
        },
        {
            title: 'Person',
            dataIndex: 'personId',
            key: 'personId',
            render: (val: number) => <span>{toNumberString(val)}</span>,
            sorter: (a: AnnotatedDrawablePoint, b: AnnotatedDrawablePoint) =>
                a.personId < b.personId ? -1 : 1,
            sortDirections: ['descend' as SortOrder, 'ascend' as SortOrder],
            filterDropdown: BasicFilterDropdown,
            filterIcon: FilterIcon,
            onFilter: (filter, record) => {
                return toNumberString(Number(record.personId))
                    .toLowerCase()
                    .includes(filter.toString().toLowerCase());
            },
        },
        /* { // only showing pose for now
            title: 'Part',
            dataIndex: 'partId',
            key: 'partId',
            sorter: (a: AnnotatedDrawablePoint, b: AnnotatedDrawablePoint) => a.partId < b.partId ? -1 : 1,
            sortDirections: ['descend' as SortOrder, 'ascend' as SortOrder]
        }, */
        {
            title: '',
            dataIndex: 'color',
            key: 'color',
            width: 'min-content',
            render: (val: string) => (val ? <ColorContainer color={val}></ColorContainer> : null),
        },
        {
            title: 'Point',
            dataIndex: 'index',
            key: 'index',
            sorter: (a: AnnotatedDrawablePoint, b: AnnotatedDrawablePoint) =>
                a.index < b.index ? -1 : 1,
            sortDirections: ['descend' as SortOrder, 'ascend' as SortOrder],
            filterDropdown: BasicFilterDropdown,
            filterIcon: FilterIcon,
            onFilter: (filter, record) => strIncludesCaseInsensitive(filter, record.index),
        },
    ];

    constructor(props: Props) {
        super(props);

        this.state = {
            points: [],
            filteredPoints: [],
            lines: [],
            filteredLines: [],
            sliderVal: 0.5,
            marks: {},
            overlayOpacity: 0,
        };
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.answer !== prevProps.answer) {
            this.update();
        }
    }

    update() {
        if (this.props.answer) {
            const people = this.props.answer
                ? this.props.answer.people.map((p) => convertPersonToDrawable(p))
                : [];
            const points = people.flatMap((p, i) => getAnnotatedPoints(p, i.toString()));
            const lines = people.flatMap((p) => getLines(p));
            const pointsConf = points.map((p) => p.confidence).sort();
            const marks: Dictionary<string> = {};
            pointsConf.forEach((m) => (marks[m] = '|'));
            this.setState({
                lines,
                filteredLines: lines,
                points,
                filteredPoints: points,
                sliderVal: pointsConf.length ? pointsConf.sort()[0] : 0.5,
                marks,
            });
            this.animator.start();
        }
    }

    drawAnswer = (
        ctx: CanvasRenderingContext2D,
        lines: DrawableLine[],
        points: AnnotatedDrawablePoint[],
        scale: number
    ) => {
        ctx.lineWidth = this.lineWidth;
        ctx.globalAlpha = this.state.overlayOpacity;
        lines.forEach((line) => {
            const startX = scale * line.start.x;
            const startY = scale * line.start.y;
            const endX = scale * line.end.x;
            const endY = scale * line.end.y;
            const grad = ctx.createLinearGradient(startX, startY, endX, endY);
            grad.addColorStop(0, line.start.color);
            grad.addColorStop(1, line.end.color);
            ctx.strokeStyle = grad;
            ctx.beginPath();
            ctx.moveTo(startX, startY);
            ctx.lineTo(endX, endY);
            ctx.stroke();
        });
        points.forEach((point) => {
            const x = scale * point.x;
            const y = scale * point.y;
            ctx.fillStyle = point.color;
            ctx.beginPath();
            ctx.arc(x, y, this.lineWidth, 0, 2 * Math.PI);
            ctx.fill();
        });
    };

    handelSliderChange = (value: any) => {
        const { lines, points } = this.state;
        const filteredLines = lines.filter(
            (l) => l.start.confidence >= value && l.end.confidence >= value
        );
        const filteredPoints = points.filter((p) => p.confidence >= value);
        this.setState({
            filteredPoints,
            filteredLines,
            sliderVal: value,
        });
    };

    render() {
        const data: AnnotatedDrawablePoint[] = this.state.points;

        return (
            <PropertiesArea>
                {this.props.originalImgSrc ? (
                    <Collapse.Panel header="Poses" key="image">
                        <ImageArea>
                            <CanvasImage
                                src={this.props.originalImgSrc}
                                drawPropHash={`${this.state.overlayOpacity}_${this.state.filteredLines}_${this.state.filteredPoints}`}
                                draw={
                                    this.props.answer
                                        ? (
                                              ctx: CanvasRenderingContext2D,
                                              padding: number,
                                              scale: number
                                          ) =>
                                              this.drawAnswer(
                                                  ctx,
                                                  this.state.filteredLines,
                                                  this.state.filteredPoints,
                                                  scale
                                              )
                                        : undefined
                                }
                            />
                        </ImageArea>
                    </Collapse.Panel>
                ) : null}
                {this.props.answer ? (
                    <Collapse.Panel header="Image Key" key="key">
                        <KeyGrid>
                            <img src={astroSrc} alt="Astronaut" />
                        </KeyGrid>
                    </Collapse.Panel>
                ) : null}
                {this.props.answer ? (
                    <Collapse.Panel header="Minimum Confidence" key="confidence">
                        <FullWidthSlider
                            min={0}
                            max={1}
                            step={0.01}
                            value={this.state.sliderVal}
                            onChange={this.handelSliderChange}
                            marks={this.state.marks}
                        />
                    </Collapse.Panel>
                ) : null}
                {this.props.answer ? (
                    <Collapse.Panel header="Predicted Pose Points" key="table1">
                        <Table
                            size="small"
                            scroll={{ x: true }}
                            rowKey={(record) =>
                                `${record.personId}_${record.partId}_${record.index}`
                            }
                            columns={this.columns}
                            dataSource={data}
                            pagination={
                                data.length > this.pageSize && {
                                    pageSize: this.pageSize,
                                    simple: true,
                                }
                            }
                        />
                    </Collapse.Panel>
                ) : null}
            </PropertiesArea>
        );
    }
}

const KeyGrid = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: ${({ theme }) => `${theme.spacing.xs}`};
    align-items: center;

    img {
        width: 100%;
        object-fit: contain;
        max-height: 150px;
    }
`;
