import * as React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Tooltip, Collapse, Table } from '@allenai/varnish';
import { BasicFilterDropdown, FilterIcon, ColumnProps } from '@allenai/varnish/es/table';
import { chartingColor, RGB } from '@allenai/varnish/es/varnish/colors';
import { SortOrder } from '@allenai/varnish/es/table/interface';

import { SegmentationAnswer, Label } from '../api';
import { CanvasImage } from './CanvasImage';
import { AnswerInfoProps, ImageArea, PropertiesArea, FullWidthSlider } from '.';
import { Animator, strIncludesCaseInsensitive } from '../utils';
import { ColorContainer } from './shared';

interface State {
    overlayOpacity: number;
    hoverLabel?: Label;
}

type Props = AnswerInfoProps<SegmentationAnswer>;
type SegmentationItem = { name: string; color?: string };

export class SegmentationAnswerInfo extends React.Component<Props, State> {
    maxSegmentOverlayOpacity = 1; // todo: remove this and its uses if we stick with 1
    animator: Animator = new Animator({
        startValue: 0,
        endValue: 0.7,
        duration: 1200,
        onChange: (value: number) => {
            this.setState({ overlayOpacity: value });
        },
    });

    pageSize = 6;
    classDict: { [id: string]: RGB } = {};

    columns: ColumnProps<SegmentationItem>[] = [
        {
            title: '',
            dataIndex: 'color',
            key: 'color',
            width: 'min-content',
            render: (val: string) => (val ? <ColorContainer color={val}></ColorContainer> : null),
        },
        {
            title: 'Segment',
            dataIndex: 'name',
            key: 'name',
            sorter: (a: SegmentationItem, b: SegmentationItem) => (a.name < b.name ? -1 : 1),
            sortDirections: ['descend' as SortOrder, 'ascend' as SortOrder],
            filterDropdown: BasicFilterDropdown,
            filterIcon: FilterIcon,
            onFilter: (filter, record) => strIncludesCaseInsensitive(filter, record.name),
        },
    ];

    constructor(props: Props) {
        super(props);

        this.state = {
            overlayOpacity: 0,
        };
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.answer !== prevProps.answer) {
            this.update();
        }
    }

    update() {
        if (this.props.answer) {
            this.animator.start();
        }
    }

    canvasSpaceToImageSpace(v: number, scale: number) {
        return Math.floor(v / (scale > 0 ? scale : 1));
    }

    // convert image to grayscale, then apply the segment color on top based on animated opacity
    drawAnswer = (
        ctx: CanvasRenderingContext2D,
        scale: number,
        segmentationAnswer?: SegmentationAnswer
    ) => {
        let usedColorindex = 0;
        const palette = Object.values(chartingColor);

        if (segmentationAnswer && segmentationAnswer.segmentation.image.length) {
            const imageData = ctx.getImageData(
                0,
                0,
                ctx.canvas.clientWidth,
                ctx.canvas.clientHeight
            );
            for (let j = 0; j < imageData.width; j++) {
                for (let i = 0; i < imageData.height; i++) {
                    const scaledX = this.canvasSpaceToImageSpace(j, scale);
                    const scaledY = this.canvasSpaceToImageSpace(i, scale);
                    const val = segmentationAnswer.segmentation.image[scaledX][scaledY];

                    // are we an edge, use darker opacity
                    let colorOpacity = 1;
                    if (
                        colorOpacity !== this.maxSegmentOverlayOpacity &&
                        j > 0 &&
                        i > 0 &&
                        j < imageData.width - 1 &&
                        i < imageData.height - 1
                    ) {
                        const scaledLeft = this.canvasSpaceToImageSpace(j - 1, scale);
                        const scaledRight = this.canvasSpaceToImageSpace(j + 1, scale);
                        const scaledUp = this.canvasSpaceToImageSpace(i - 1, scale);
                        const scaledDown = this.canvasSpaceToImageSpace(i + 1, scale);
                        if (
                            val === segmentationAnswer.segmentation.image[scaledLeft][scaledY] &&
                            val === segmentationAnswer.segmentation.image[scaledRight][scaledY] &&
                            val === segmentationAnswer.segmentation.image[scaledX][scaledUp] &&
                            val === segmentationAnswer.segmentation.image[scaledX][scaledDown]
                        ) {
                            colorOpacity = this.maxSegmentOverlayOpacity;
                        }
                    }

                    let color = this.classDict[val.toString()];
                    if (!color) {
                        color = palette[usedColorindex++ % palette.length].rgb;
                        this.classDict[val.toString()] = color;
                    }
                    const index = i * 4 * imageData.width + j * 4;
                    const red = imageData.data[index];
                    const green = imageData.data[index + 1];
                    const blue = imageData.data[index + 2];
                    const average = ((red + green + blue) / 3) * (1 - colorOpacity);
                    // transitioning from color with no class overlay
                    // to grayscale with overlay
                    imageData.data[index] =
                        red * (1 - this.state.overlayOpacity) +
                        this.state.overlayOpacity * (average + color.r * colorOpacity);
                    imageData.data[index + 1] =
                        green * (1 - this.state.overlayOpacity) +
                        this.state.overlayOpacity * (average + color.g * colorOpacity);
                    imageData.data[index + 2] =
                        blue * (1 - this.state.overlayOpacity) +
                        this.state.overlayOpacity * (average + color.b * colorOpacity);
                }
            }
            ctx.putImageData(imageData, 0, 0);
        }
    };

    // on mouse move, display a tooltip at the bottom saying what is hovered on
    // we can do something other than a tooltip in the future
    onMouseMove = (evt: any, canvas: HTMLCanvasElement, padding: number, scale: number) => {
        if (canvas && this.props.answer && this.props.answer.segmentation.image.length) {
            const rect = canvas.getBoundingClientRect();
            const x = this.canvasSpaceToImageSpace(evt.clientX - rect.left, scale);
            const y = this.canvasSpaceToImageSpace(evt.clientY - rect.top, scale);
            const val = this.props.answer.segmentation.image[x][y];
            const labels = this.props.answer.segmentation.labels.filter((s) => val === s.code);
            this.setState({ hoverLabel: labels[0] });
        }
    };

    getColorForCode = (code: number) => {
        return this.classDict[code.toString()]
            ? this.classDict[code.toString()].toString()
            : undefined;
    };

    handelOpacitySliderChange = (value: any) => {
        this.setState({
            overlayOpacity: value,
        });
    };

    render() {
        const data: SegmentationItem[] = this.props.answer
            ? this.props.answer.segmentation.labels.map((k) => {
                  return {
                      name: k.name,
                      color: this.getColorForCode(k.code),
                  };
              })
            : [];

        return (
            <React.Fragment>
                {
                    console.log(
                        this.classDict
                    ) /* todo: remove this once we debug https://github.com/allenai/prior-demo/issues/12 */
                }
                <PropertiesArea>
                    {this.props.originalImgSrc ? (
                        <Collapse.Panel header="Segments" key="image">
                            <React.Fragment>
                                <WithStyledTooltip
                                    color={
                                        this.state.hoverLabel
                                            ? this.getColorForCode(this.state.hoverLabel.code)
                                            : undefined
                                    }
                                />
                                <Tooltip
                                    title={
                                        this.state.hoverLabel
                                            ? this.state.hoverLabel.name
                                            : undefined
                                    }
                                    placement="bottomLeft"
                                    overlayClassName="overlay">
                                    <ImageArea>
                                        <CanvasImage
                                            src={this.props.originalImgSrc}
                                            drawPropHash={`${this.state.overlayOpacity}`}
                                            onMouseMove={this.onMouseMove}
                                            draw={
                                                this.props.answer
                                                    ? (
                                                          ctx: CanvasRenderingContext2D,
                                                          padding: number,
                                                          scale: number
                                                      ) =>
                                                          this.drawAnswer(
                                                              ctx,
                                                              scale,
                                                              this.props.answer
                                                          )
                                                    : undefined
                                            }
                                        />
                                    </ImageArea>
                                </Tooltip>
                            </React.Fragment>
                        </Collapse.Panel>
                    ) : null}
                    {this.props.answer ? (
                        <Collapse.Panel header="Segment Opacity" key="opacity">
                            <FullWidthSlider
                                min={0}
                                max={1}
                                step={0.02}
                                value={this.state.overlayOpacity}
                                onChange={this.handelOpacitySliderChange}
                            />
                        </Collapse.Panel>
                    ) : null}
                    {this.props.answer ? (
                        <Collapse.Panel header="Predicted Segments" key="table1">
                            <Table
                                size="small"
                                scroll={{ x: true }}
                                rowKey={(record) => record.name}
                                columns={this.columns}
                                dataSource={data}
                                pagination={
                                    data.length > this.pageSize && {
                                        pageSize: this.pageSize,
                                        simple: true,
                                    }
                                }
                            />
                        </Collapse.Panel>
                    ) : null}
                </PropertiesArea>
            </React.Fragment>
        );
    }
}

const WithStyledTooltip = createGlobalStyle<{ color?: string }>`
    .overlay {
        .ant-tooltip-content {
            .ant-tooltip-inner {
                color: black;
                border: ${({ color }) => `solid 2px ${color}`};
                background: white;
                border-radius: 4px;
                min-width: 270px;
            }

            .ant-tooltip-arrow {
                border-left-color: ${({ color }) => color};
                border-top-color: ${({ color }) => color};
            }
        }
    }
`;
