// todo: move to varnish

import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
    title: string;
    description: string;
}

export const MetaTags = ({ title, description }: Props) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="twitter:title" content={title} />
            <meta property="og:title" content={title} />

            <meta name="description" content={description} />
            <meta name="twitter:description" content={description} />
            <meta property="og:description" content={description} />
        </Helmet>
    );
};
