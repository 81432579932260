import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '@allenai/varnish';
import { belowOrEqualTo } from '@allenai/varnish/es/varnish/breakpoints';

import { MetaTags } from '../components';

import priorSrc from '../icons/prior-transparent.svg';
import captionSrc from '../icons/home_caption.jpg';
import poseSrc from '../icons/home_pose.jpg';
import objectSrc from '../icons/home_object.jpg';
import shareFacebookSrc from '../icons/social-facebook-32px.svg';
import shareTwitterSrc from '../icons/social-twitter-32px.svg';
import shareLinkedinSrc from '../icons/social-linkedin-32px.svg';

const { Body, BodySmall } = Typography;

export class Home extends React.PureComponent {
    render() {
        return (
            <React.Fragment>
                <MetaTags
                    title="Home"
                    description="The AI2 Computer Vision Explorer offers demos of a variety of popular models — try, compare, and evaluate with your own images!"
                />
                <UsageArea>
                    <Usage>
                        <UsageImg
                            src={captionSrc}
                            alt="Screenshot of image captions for a photo of a cyclist"
                        />
                        <UsageDescription>
                            Try out leading computer vision models on a variety of tasks
                        </UsageDescription>
                    </Usage>
                    <Usage>
                        <UsageImg
                            src={poseSrc}
                            alt="Screenshot of estimated poses of people dancing"
                        />
                        <UsageDescription>Compare popular models</UsageDescription>
                    </Usage>
                    <Usage>
                        <UsageImg
                            src={objectSrc}
                            alt="Screenshot of AI detections in a room with people"
                        />
                        <UsageDescription>Test performance on your own images</UsageDescription>
                    </Usage>
                </UsageArea>
                <ShareArea>
                    <Body>Share this:</Body>
                    <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fvision-explorer.allenai.org%2F">
                        <img src={shareFacebookSrc} alt="Share on Facebook" />
                    </a>
                    <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fvision-explorer.allenai.org%2F&text=The%20AI2%20Computer%20Vision%20Explorer%20offers%20demos%20of%20a%20variety%20of%20popular%20models%20-%20try%2C%20compare%2C%20and%20evaluate%20with%20your%20own%20images%21">
                        <img src={shareTwitterSrc} alt="Share on Twitter" />
                    </a>
                    <a href="http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fvision-explorer.allenai.org%2F&title=Computer%20Vision%20Explorer&summary=The%20AI2%20Computer%20Vision%20Explorer%20offers%20demos%20of%20a%20variety%20of%20popular%20models%20-%20try%2C%20compare%2C%20and%20evaluate%20with%20your%20own%20images%21">
                        <img src={shareLinkedinSrc} alt="Share on Linkedin" />
                    </a>
                </ShareArea>
                <TeamBackground>
                    <TeamContent>
                        <a href="https://prior.allenai.org/">
                            <LogoPrior src={priorSrc} alt="PRIOR" />
                        </a>
                        <BodySmall>
                            This demo page is built and maintained by{' '}
                            <a href="https://prior.allenai.org/">PRIOR</a> and colleagues at the
                            &nbsp;
                            <a href="http://allenai.org">Allen Institute for AI</a>. Our team seeks
                            to advance computer vision to create AI systems that see, explore,
                            learn, and reason about the world.
                        </BodySmall>
                    </TeamContent>
                </TeamBackground>
            </React.Fragment>
        );
    }
}

const UsageArea = styled.div`
    display: grid;
    gap: ${({ theme }) => theme.spacing.xl2};
    margin-bottom: ${({ theme }) => theme.spacing.xl2};
`;

const Usage = styled.div`
    display: grid;
    grid-template-columns: 4fr 6fr;
    gap: ${({ theme }) => theme.spacing.xl};

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.sm)} {
        grid-template-columns: 1fr;
        gap: ${({ theme }) => theme.spacing.xs};
    }
`;

const UsageImg = styled.img`
    width: 100%;
    max-width: 330px;

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.sm)} {
        order: 1;
    }
`;

const UsageDescription = styled.h4`
    font-weight: 700;
`;

const ShareArea = styled.div`
    display: grid;
    grid-template-columns: repeat(4, auto) 1fr;
    gap: ${({ theme }) => theme.spacing.xs};
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spacing.xl2};
`;

const TeamBackground = styled.div`
    background: ${({ theme }) => theme.color.N2};
    margin: ${({ theme }) => `0 -${theme.spacing.md} ${theme.spacing.xl5} -${theme.spacing.md}`};
    padding: ${({ theme }) => theme.spacing.md};
`;

const TeamContent = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.md};

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.md)} {
        grid-template-columns: 1fr;
    }
`;

const LogoPrior = styled.img`
    width: 133px;
    max-width: 100%;
`;
