import * as React from 'react';
import styled from 'styled-components';

interface Props {
    src?: string;
    padding?: number;
    draw?: (context: CanvasRenderingContext2D, padding: number, scale: number) => void;
    drawPropHash?: string; // used to force a redraw if caller has a new set of stuff used in the draw func
    onMouseMove?: (e: any, canvas: HTMLCanvasElement, padding: number, scale: number) => void;
}

interface State {
    wrapperRef: React.RefObject<HTMLDivElement>;
    canvasRef: React.RefObject<HTMLCanvasElement>;
    imageRef: React.RefObject<HTMLImageElement>;
}

export class CanvasImage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            wrapperRef: React.createRef(),
            canvasRef: React.createRef(),
            imageRef: React.createRef(),
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.renderSubsequentImage);
        const img = this.state.imageRef.current;
        if (img) {
            img.onload = () => {
                this.renderImage(true);
            };
        }
    }

    componentDidUpdate(prevProps: Props) {
        // if passed in props function has new props, we wantr to rerender
        if (this.props.draw && prevProps.drawPropHash !== this.props.drawPropHash) {
            this.renderSubsequentImage();
        }
    }

    // variable used to hold an anonymous function calling the passes in onMouseMove prop function
    onMouseMove?: (e: MouseEvent) => void = undefined;

    componentWillUnmount() {
        window.removeEventListener('resize', this.renderSubsequentImage);
    }

    renderSubsequentImage = () => {
        return this.renderImage();
    };

    // initial render forces canvas to be a tad bit larger, to force a redraw, which speeds up
    // the animation for some reason
    renderImage = (initialRender: boolean = false) => {
        const wrapper = this.state.wrapperRef.current;
        const canvas = this.state.canvasRef.current;
        const img = this.state.imageRef.current;
        if (canvas && img && wrapper) {
            const pad = this.props.padding || 0;
            canvas.width = wrapper.clientWidth;
            const scale = (canvas.width - 2 * pad) / img.width;
            canvas.height = Math.ceil((initialRender ? 1 : 0) + scale * img.height + 2 * pad);
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.drawImage(img, pad, pad, scale * img.width, scale * img.height);
                if (this.props.draw) {
                    this.props.draw(ctx, pad, scale);
                }
            }

            // if the user wants allerts when the mouse moves on the canvas,
            // we remove any previously attached listener, and add a new one
            if (this.props.onMouseMove) {
                if (this.onMouseMove) {
                    canvas.removeEventListener('mousemove', this.onMouseMove);
                }

                this.onMouseMove = (e: MouseEvent) => {
                    this.props.onMouseMove && this.props.onMouseMove(e, canvas, pad, scale);
                };
                canvas.addEventListener('mousemove', this.onMouseMove);
            }
        }
    };

    render() {
        return (
            <Wrapper ref={this.state.wrapperRef}>
                <canvas ref={this.state.canvasRef} />
                <HiddenImage ref={this.state.imageRef} src={this.props.src} />
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    width: 100%;
`;

const HiddenImage = styled.img`
    display: none;
`;
