import * as React from 'react';
import styled from 'styled-components';
import { Button } from '@allenai/varnish';
import { GithubOutlined } from '@ant-design/icons';

import { AnimatedLoading, Error, AnswerInfoProps, Answer, Markdown } from '.';
import { BaseQuery, BaseRequest } from '../api';
import { Attribution } from '../tasks';

export enum BaseAnswerView {
    EMPTY,
    LOADING,
    ANSWER,
    ERROR,
}

interface Props<A extends Answer> {
    name: string;
    markdownDescription: string;
    attribution?: Attribution;
    answerInfo: new (props: AnswerInfoProps<A>) => React.Component<AnswerInfoProps<A>>;
    apiCall: BaseRequest<A>;
    estimatedApiDuration: number;
    originalImgSrc?: string;
    query: BaseQuery;
    onStatusChange: (modelName: string, modelState: BaseAnswerView) => void;
}

interface State<A extends Answer> {
    view: BaseAnswerView;
    answer?: A;
    error?: string;
}

export class BaseAnswer<A extends Answer> extends React.PureComponent<Props<A>, State<A>> {
    constructor(props: Props<A>) {
        super(props);
        this.state = {
            view: BaseAnswerView.EMPTY,
        };
    }

    componentDidUpdate(prevProps: Props<A>, prevState: State<A>) {
        if (prevState.view !== this.state.view) {
            // announce if we are loading or not
            this.props.onStatusChange(this.props.name, this.state.view);
        }
        if (
            this.props.query &&
            this.props.query.isValid() &&
            prevProps.query !== this.props.query
        ) {
            // if we get a new query, reset state to EMPTY
            this.setState({ view: BaseAnswerView.EMPTY });
        }
    }

    /**
     * Submits an API query for an answer for the current query.
     *
     * @returns {void}
     */
    fetchAnswer() {
        // We store a local variable capturing the value of the current
        // query. We use this as a semaphore / lock of sorts, since the
        // API query is asynchronous.
        const originalQuery = this.props.query;
        this.setState({ view: BaseAnswerView.LOADING, answer: undefined }, () => {
            this.props
                .apiCall(this.props.query)
                .then((answerResponse) => {
                    // When the API returns successfully we make sure that
                    // the returned answer is for the last submitted query.
                    // This way we avoid displaying an answer that's not
                    // associated with the last query the user submitted.

                    if (this.props.query.equals(originalQuery)) {
                        if (undefined === answerResponse.answer.error) {
                            this.setState({
                                view: BaseAnswerView.ANSWER,
                                error: undefined,
                                answer: answerResponse.answer,
                            });
                        } else {
                            this.setState({
                                view: BaseAnswerView.ERROR,
                                answer: undefined,
                                error: answerResponse.answer.error,
                            });
                        }
                    }
                })
                .catch((err) => {
                    // Again, make sure that the error is associated with the
                    // last submitted query.
                    if (this.props.query.equals(originalQuery)) {
                        // Try to see if there's a more specific error
                        // we're supposed to display.
                        let error;
                        if (err.response) {
                            // If the check below is true, the API returned
                            // error message that's likely helpful to display
                            if (err.response.data && err.response.data.error) {
                                error = err.response.data.error;
                            } else if (err.response.status === 503) {
                                error =
                                    'Our system is a little overloaded, ' +
                                    'please try again in a moment';
                            }
                        }

                        // Fallback to a general error message
                        if (!error) {
                            error = 'Something went wrong. Please try again.';
                        }
                        this.setState({
                            view: BaseAnswerView.ERROR,
                            answer: undefined,
                            error,
                        });
                    }
                });
        });
    }

    render() {
        const { answerInfo: AnswerInfo } = this.props;
        const runTime =
            this.props.estimatedApiDuration < 30000
                ? 'a few seconds'
                : this.props.estimatedApiDuration < 61000
                ? 'a minute'
                : 'a few minutes';

        return (
            <React.Fragment>
                <ModelTitle>{this.props.name}</ModelTitle>
                {this.props.attribution ? (
                    <AttributionArea>
                        <PaperLink
                            href={this.props.attribution.paper.url}
                            target="_blank"
                            rel="noopener">
                            {this.props.attribution.paper.label}
                        </PaperLink>
                        <AuthorList>
                            {this.props.attribution.authors.map((author) => (
                                <span key={author}>{author}</span>
                            ))}
                        </AuthorList>
                        <Bullet />
                        {this.props.attribution.publication}
                        <Bullet />
                        {this.props.attribution.publicationYear}
                        {this.props.attribution.repo ? (
                            <React.Fragment>
                                <Bullet />
                                <a
                                    href={this.props.attribution.repo.url}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {this.props.attribution.repo.label === 'github' ? (
                                        <GithubOutlined />
                                    ) : (
                                        <span>this.props.attribution.repo.label</span>
                                    )}
                                </a>
                            </React.Fragment>
                        ) : null}
                    </AttributionArea>
                ) : null}
                <Markdown>{this.props.markdownDescription}</Markdown>
                <AnswerArea>
                    {this.props.query && this.props.query.isValid() ? (
                        <React.Fragment>
                            {this.state.view === BaseAnswerView.EMPTY ? (
                                <RunButtonArea>
                                    <SpacedButton type="primary" onClick={() => this.fetchAnswer()}>
                                        Run Model
                                    </SpacedButton>
                                    <span>Run time: {runTime}</span>
                                </RunButtonArea>
                            ) : null}
                            <SubmitContainer>
                                {this.state.view === BaseAnswerView.LOADING ? (
                                    <AnimatedLoading
                                        startValue={5}
                                        endValue={95}
                                        duration={this.props.estimatedApiDuration}
                                    />
                                ) : null}
                                {this.state.view === BaseAnswerView.ERROR && this.state.error ? (
                                    <Error message={this.state.error} />
                                ) : null}
                            </SubmitContainer>
                            {this.state.view !== BaseAnswerView.EMPTY ? (
                                <AnswerInfo
                                    answer={this.state.answer}
                                    originalImgSrc={this.props.originalImgSrc}
                                />
                            ) : undefined}
                        </React.Fragment>
                    ) : null}
                </AnswerArea>
            </React.Fragment>
        );
    }
}

const ModelTitle = styled.h4`
    margin-bottom: ${({ theme }) => theme.spacing.xxs};
`;

const SubmitContainer = styled.div`
    height: 0;
    overflow: visible;
`;

const PaperLink = styled.a`
    display: block;
`;

const AttributionArea = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.xxs};
`;

const Bullet = styled.span`
    &::after {
        margin: 0 0.1875rem;
        font-size: 0.5rem;
        content: '•';
        vertical-align: middle;
    }
`;

const AuthorList = styled.span`
    span:not(:last-child):after {
        content: ', ';
    }
`;

const RunButtonArea = styled.div`
    display: grid;
    grid-template-columns: min-content auto;
    align-items: baseline;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        grid-template-columns: auto;
    }
`;

const SpacedButton = styled(Button)`
    margin-top: ${({ theme }) => theme.spacing.sm};
    margin-right: ${({ theme }) => theme.spacing.md};
`;

const AnswerArea = styled.div`
    margin-top: ${({ theme }) => theme.spacing.xs};
    margin-bottom: ${({ theme }) => theme.spacing.xl};
`;
