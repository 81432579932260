import * as React from 'react';
import { Table, Collapse, Theme } from '@allenai/varnish';
import { BasicFilterDropdown, FilterIcon, ColumnProps } from '@allenai/varnish/es/table';
import { chartingColor, Color } from '@allenai/varnish/es/varnish/colors';
import { SortOrder } from '@allenai/varnish/es/table/interface';

import { ObjectDetectionAnswer, Detection } from '../api';
import { CanvasImage } from './CanvasImage';
import {
    AnswerInfoProps,
    ImageArea,
    SparkEnvelope,
    Spark,
    SparkValue,
    PropertiesArea,
    FullWidthSlider,
} from '.';
import { Animator, Dictionary, strIncludesCaseInsensitive } from '../utils';

interface State {
    detections: Detection[];
    sliderVal: number;
    marks: {};
    overlayOpacity: number;
}

type Props = AnswerInfoProps<ObjectDetectionAnswer>;

export class ObjectDetectionAnswerInfo extends React.Component<Props, State> {
    fontPad = 4;
    lineWidth = 1.5;
    animator: Animator = new Animator({
        startValue: 0,
        endValue: 0.7,
        duration: 1200,
        onChange: (value: number) => {
            this.setState({ overlayOpacity: value });
        },
    });

    pageSize = 6;

    columns: ColumnProps<Detection>[] = [
        {
            title: 'Confidence',
            dataIndex: 'confidence',
            key: 'confidence',
            render: (val: number) => (
                <div title={val.toString()}>
                    <SparkEnvelope>
                        <Spark value={val} />
                    </SparkEnvelope>{' '}
                    <SparkValue>{`${(100 * val).toFixed(1)}%`}</SparkValue>
                </div>
            ),
            sorter: (a: Detection, b: Detection) => a.confidence - b.confidence,
            sortDirections: ['descend' as SortOrder, 'ascend' as SortOrder],
            defaultSortOrder: 'descend' as SortOrder,
        },
        {
            title: 'Class',
            dataIndex: 'class',
            key: 'class',
            sorter: (a: Detection, b: Detection) => (a.class < b.class ? -1 : 1),
            sortDirections: ['descend' as SortOrder, 'ascend' as SortOrder],
            filterDropdown: BasicFilterDropdown,
            filterIcon: FilterIcon,
            onFilter: (filter, record) => strIncludesCaseInsensitive(filter, record.class),
        },
    ];

    constructor(props: Props) {
        super(props);

        this.state = {
            detections: [],
            sliderVal: 0.5,
            marks: {},
            overlayOpacity: 0,
        };
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.answer !== prevProps.answer) {
            this.update();
        }
    }

    update() {
        if (this.props.answer) {
            const det = this.props.answer.detections;
            const detConf = det.map((d) => d.confidence).sort();
            const marks: Dictionary<string> = {};
            detConf.forEach((m) => (marks[m] = '|'));
            this.setState({
                detections: det,
                sliderVal: detConf.length ? detConf.sort()[0] : 0.5,
                marks,
            });
            this.animator.start();
        }
    }

    getFontSize() {
        return Math.max(7, Math.min(16, window.innerWidth / 32));
    }

    drawAnswer = (
        ctx: CanvasRenderingContext2D,
        detections: Detection[],
        padding: number,
        scale: number
    ) => {
        let usedColorindex = 0;
        const classDict: { [id: string]: Color } = {};
        const fontSize = this.getFontSize();
        ctx.lineWidth = this.lineWidth;
        ctx.font = fontSize + 'px Lato';
        const palette = Object.values(chartingColor);
        detections.forEach((d) => {
            const scaledDims = {
                start_x: scale * d.start_x,
                start_y: scale * d.start_y,
                end_x: scale * d.end_x,
                end_y: scale * d.end_y,
            };

            let color = classDict[d.class];
            if (!color) {
                color = palette[usedColorindex++ % palette.length];
                classDict[d.class] = color;
            }

            ctx.globalAlpha = this.state.overlayOpacity;

            ctx.beginPath();
            ctx.strokeStyle = color.hex;
            ctx.rect(
                padding + scaledDims.start_x,
                padding + scaledDims.start_y,
                scaledDims.end_x - scaledDims.start_x,
                scaledDims.end_y - scaledDims.start_y
            );
            ctx.stroke();

            const label = `${d.class} (${d.confidence.toFixed(2)})`;
            ctx.fillStyle = color.hex;
            ctx.fillRect(
                padding + scaledDims.start_x - this.lineWidth / 2,
                padding + scaledDims.start_y - fontSize - this.fontPad,
                Math.max(
                    scaledDims.end_x - scaledDims.start_x + this.lineWidth,
                    ctx.measureText(label).width + this.fontPad * 2
                ),
                fontSize + this.fontPad
            );

            ctx.globalAlpha = 1;
            ctx.fillStyle = color.useContrastText
                ? Theme.default.palette.text.contrast.hex
                : Theme.default.palette.text.primary.hex;
            ctx.fillText(
                label,
                padding + scaledDims.start_x + this.fontPad,
                padding + scaledDims.start_y - this.fontPad
            );
        });
    };

    handelSliderChange = (value: any) => {
        this.setState({
            detections: this.props.answer
                ? this.props.answer.detections.filter((d) => d.confidence >= value)
                : [],
            sliderVal: value,
        });
    };

    render() {
        const data: Detection[] = this.props.answer ? this.props.answer.detections : [];

        return (
            <PropertiesArea>
                {this.props.originalImgSrc ? (
                    <Collapse.Panel header="Detections" key="image">
                        <ImageArea>
                            <CanvasImage
                                padding={this.getFontSize() + this.fontPad}
                                src={this.props.originalImgSrc}
                                drawPropHash={`${this.state.overlayOpacity}_${this.state.detections}`}
                                draw={
                                    this.props.answer
                                        ? (
                                              ctx: CanvasRenderingContext2D,
                                              padding: number,
                                              scale: number
                                          ) =>
                                              this.drawAnswer(
                                                  ctx,
                                                  this.state.detections,
                                                  padding,
                                                  scale
                                              )
                                        : undefined
                                }
                            />
                        </ImageArea>
                    </Collapse.Panel>
                ) : null}
                {this.props.answer ? (
                    <Collapse.Panel header="Minimum Confidence" key="confidence">
                        <FullWidthSlider
                            min={0}
                            max={1}
                            step={0.01}
                            value={this.state.sliderVal}
                            onChange={this.handelSliderChange}
                            marks={this.state.marks}
                        />
                    </Collapse.Panel>
                ) : null}
                {this.props.answer ? (
                    <Collapse.Panel header="Predicted Classes" key="table1">
                        <Table
                            size="small"
                            scroll={{ x: true }}
                            rowKey={(record) => `${record.class}_${record.confidence}`}
                            columns={this.columns}
                            dataSource={data}
                            pagination={
                                data.length > this.pageSize && {
                                    pageSize: this.pageSize,
                                    simple: true,
                                }
                            }
                        />
                    </Collapse.Panel>
                ) : null}
            </PropertiesArea>
        );
    }
}
