import styled from 'styled-components';

export interface BaseModelParams {
    label?: string;
}

export interface ImageModelParams extends BaseModelParams {
    img1Src?: string;
    imageName?: string;
    image?: File;
}

export interface ModelParamComponentUiParams {
    getInputLabel: (id: string) => JSX.Element | null;
}

export interface ModelParamControlProps<T extends BaseModelParams> {
    modelParams: T;
    disabled: boolean;
    onChange: (newParams: T) => void;
    onLoading?: (isLoading: boolean) => void;
    modelParamComponentUiParams: ModelParamComponentUiParams;
}

export const ModelParamLabel = styled.div`
    margin: ${({ theme }) => `${theme.spacing.sm} 0 ${theme.spacing.xxs} 0`};
`;
