import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '@allenai/varnish';
import { belowOrEqualTo } from '@allenai/varnish/es/varnish/breakpoints';

import { MetaTags } from '../components';

import priorSrc from '../icons/prior-transparent.svg';

const { Body, BodySmall } = Typography;

export class About extends React.PureComponent {
    render() {
        return (
            <Body>
                <MetaTags
                    title="About"
                    description="Additional information about the Computer Vision Explorer."
                />

                <p>
                    Over the past decade, there has been tremendous progress in the field of
                    computer vision. Thousands of research papers are published yearly, with many
                    obtaining state of the art results on established benchmarks.
                </p>
                <p>
                    With this frenetic activity, it can be challenging to get a sense of how well
                    popular models perform on data you may be working with. The PRIOR team has found
                    that exploring a model’s qualitative behaviour can provide insights that are
                    hard to get purely from tracking quantitative metrics. Online demos offer
                    researchers a quick and easy way to perform a qualitative error analysis on
                    small samples of their data and evaluate whether a given model may be useful for
                    downstream tasks.
                </p>
                <p>
                    This page showcases a number of accessible models achieving state of the art (or
                    near state of the art) results on many popular computer vision tasks. We hope
                    you find this useful!
                </p>
                <p>
                    This page is a work in progress. We will continue to add state of the art models
                    as they get released. Stay tuned!
                </p>
                <TeamBackground>
                    <TeamContent>
                        <a href="https://prior.allenai.org/">
                            <LogoPrior src={priorSrc} alt="PRIOR" />
                        </a>
                        <BodySmall>
                            This demo page is built and maintained by{' '}
                            <a href="https://prior.allenai.org/">PRIOR</a> and colleagues at the
                            &nbsp;
                            <a href="http://allenai.org">Allen Institute for AI</a>. Our team seeks
                            to advance computer vision to create AI systems that see, explore,
                            learn, and reason about the world.
                        </BodySmall>
                    </TeamContent>
                </TeamBackground>
            </Body>
        );
    }
}

const TeamBackground = styled.div`
    background: ${({ theme }) => theme.color.N2};
    margin: ${({ theme }) => `0 -${theme.spacing.md} ${theme.spacing.xl5} -${theme.spacing.md}`};
    padding: ${({ theme }) => theme.spacing.md};
`;

const TeamContent = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.md};

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.md)} {
        grid-template-columns: 1fr;
    }
`;

const LogoPrior = styled.img`
    width: 133px;
    max-width: 100%;
`;
