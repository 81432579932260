import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Upload, message } from '@allenai/varnish';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';

import { ModelParamControlProps, ModelParamLabel, ImageModelParams } from '.';
import { compressImage } from '../utils';

type Props = ModelParamControlProps<ImageModelParams>;

export const ImageParamControl: React.FunctionComponent<Props> = (props: Props) => {
    const [localState, setLocalState] = useState<ImageModelParams>({});
    const [imageLoading, setImageLoading] = useState(false);

    useEffect(
        () => {
            if (props.modelParams.img1Src !== localState.img1Src) {
                fetchImage(props.modelParams.img1Src);
            }
        },
        // eslint-disable-next-line
        [props.modelParams.img1Src]
    );

    const compressAndSubmit = (
        file: File,
        maxFileBytes: number,
        onSuccess: (file: File) => void,
        onError?: (err: string) => void
    ) => {
        if (file.size > maxFileBytes) {
            compressImage({ file, maxFileBytes, onSuccess, onError });
        } else {
            onSuccess(file);
        }
    };

    const beforeUpload = (file: any) => {
        const isImage =
            file.type === 'image/bmp' ||
            file.type === 'image/gif' ||
            file.type === 'image/png' ||
            file.type === 'image/jpeg' ||
            file.type === 'image/tiff';
        if (!isImage) {
            message.error('You can only upload JPG/PNG/BMP/GIF/TIFF files');
        } else {
            setImageLoadingAndSendEvent(true);
        }
        return isImage;
    };

    const handleImageChange = (info: any) => {
        if (info.file.status === 'done') {
            const file: File = info.file.originFileObj;
            if (file) {
                compressAndSubmit(
                    file,
                    1024 * 1024, // compressing if larger than 1MB
                    (compressedFile: File) => {
                        setStateAndSendEvent({
                            img1Src: URL.createObjectURL(compressedFile),
                            imageName: compressedFile.name,
                            image: compressedFile,
                        });
                        setImageLoadingAndSendEvent(false);
                    },
                    () => {
                        message.error(`${info.file.name} file upload failed.`);
                        setStateAndSendEvent({
                            img1Src: undefined,
                            imageName: undefined,
                            image: undefined,
                        });
                        setImageLoadingAndSendEvent(false);
                    }
                );
            }
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setStateAndSendEvent({
                img1Src: undefined,
                imageName: undefined,
                image: undefined,
            });
            setImageLoadingAndSendEvent(false);
        }
    };

    async function fetchImage(imgSrc?: string) {
        setImageLoadingAndSendEvent(true);
        let s = {
            img1Src: imgSrc,
            imageName: undefined,
            image: undefined,
        };
        if (imgSrc) {
            const response = await fetch(imgSrc);
            const blob = await response.blob();

            const file: any = blob; // convert blob to file
            file.lastModifiedDate = new Date();
            file.name = imgSrc;
            s = {
                img1Src: imgSrc,
                imageName: file.name,
                image: file,
            };
        }
        setStateAndSendEvent(s);
        setImageLoadingAndSendEvent(false);
    }

    const setStateAndSendEvent = (s: ImageModelParams) => {
        const val = { ...localState, ...s };
        setLocalState(val);
        props.onChange(val);
    };

    const setImageLoadingAndSendEvent = (imageLoading: boolean) => {
        setImageLoading(imageLoading);
        props.onLoading && props.onLoading(imageLoading);
    };

    const disableInput = () => {
        return imageLoading || props.disabled;
    };

    return (
        <React.Fragment>
            {props.modelParamComponentUiParams.getInputLabel('img1Src') ? (
                props.modelParamComponentUiParams.getInputLabel('img1Src')
            ) : (
                <ModelParamLabel>Image:</ModelParamLabel>
            )}
            <div title="Upload an Image">
                <Dragger
                    onChange={handleImageChange}
                    showUploadList={false}
                    // this is just a noop endpoint, we need an endpoint, but we dont need
                    // to save the image
                    action="api/noop"
                    beforeUpload={beforeUpload}
                    disabled={disableInput()}>
                    {imageLoading ? (
                        <DraggerMessage>
                            <LoadingOutlined /> Loading...
                        </DraggerMessage>
                    ) : null}
                    {!imageLoading && localState.img1Src ? (
                        <>
                            <DraggerImg src={localState.img1Src} />
                            <ClickToUpload msg="Click to upload a different image" />
                        </>
                    ) : null}
                    {!imageLoading && !localState.img1Src ? (
                        <ClickToUpload msg="Click to upload your own image" />
                    ) : null}
                </Dragger>
            </div>
        </React.Fragment>
    );
};

const ClickToUpload = ({ msg }: { msg: string }) => {
    return (
        <DraggerMessage>
            <UploadOutlined /> <span>{msg}</span>
        </DraggerMessage>
    );
};

const Dragger = styled(Upload.Dragger)`
    &&& {
        .ant-upload-drag {
            height: 160px;
        }

        .ant-upload-btn {
            padding: 0;
        }
    }
`;

const DraggerMessage = styled.div`
    padding: ${({ theme }) => `${theme.spacing.md} 0`};
`;

const DraggerImg = styled.img`
    max-height: ${({ theme }) => `calc(167px - ${theme.spacing.xxs} - ${theme.spacing.xxs})`};
    max-width: ${({ theme }) => `calc(100% - ${theme.spacing.xxs} - ${theme.spacing.xxs})`};
    padding: ${({ theme }) => theme.spacing.xxs};
`;
