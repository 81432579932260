/**
 * This is the top-level component that defines your UI application.
 *
 * This is an appropriate spot for application wide components and configuration,
 * stuff like application chrome (headers, footers, navigation, etc), routing
 * (what urls go where), etc.
 *
 * @see https://github.com/reactjs/react-router-tutorial/tree/master/lessons
 */

import * as React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { Route } from 'react-router-dom';
import { Footer, Header, Icon, Layout, Menu, Tag, Typography } from '@allenai/varnish';
import { Link } from '@allenai/varnish-react-router';

import { About, Home } from './pages';
import { AppRoute, AppRouteGroup } from './AppRoute';
import { categories } from './tasks';

import aboutIcon from './icons/about-14px.svg';
import headerLogo from './icons/logo.svg';

const { DefaultAppLayoutProvider, LeftSider, Content } = Layout;
const { HeaderColumns, HeaderTitle } = Header;
const { BodySmall } = Typography;
const { IconMenuItemColumns, SubMenu } = Menu;
const { ImgIcon } = Icon;

const ROUTES: AppRoute[] = [
    {
        path: '/',
        label: 'Home',
        component: Home,
    },
    {
        path: '/about',
        label: 'About',
        component: About,
        iconSrc: aboutIcon,
    },
    ...categories.reduce((acc: AppRoute[], c: AppRouteGroup) => acc.concat(c.routes), []), // flatmap
];

interface State {
    menuCollapsed: boolean;
}

export default class App extends React.PureComponent<RouteComponentProps, State> {
    siderWidthExpanded = '224px';
    /**
     * TODO: Figure out why this must be 80px, and no other value, varnish
     * sets this explicitly in CSS, so I'm not sure why the collapsedWidth
     * property is provided.
     */
    siderWidthCollapsed = '80px';
    constructor(props: RouteComponentProps) {
        super(props);

        this.state = {
            menuCollapsed: false,
        };
    }

    handleMenuCollapse = () => {
        this.setState({ menuCollapsed: !this.state.menuCollapsed });
    };

    render() {
        return (
            <Route path="/">
                <DefaultAppLayoutProvider appLayoutVariant="app">
                    <Layout bgcolor="white">
                        <Header>
                            <HeaderColumnsWithSpace gridTemplateColumns="auto auto 1fr">
                                <HeaderLink to="/">
                                    <LogoWithText>
                                        <Logo />
                                        <HeaderTitle>Computer Vision Explorer</HeaderTitle>
                                    </LogoWithText>
                                </HeaderLink>
                            </HeaderColumnsWithSpace>
                        </Header>
                        <Layout>
                            <LeftSider
                                width={this.siderWidthExpanded}
                                collapsedWidth={this.siderWidthCollapsed}
                                collapsible
                                collapsed={this.state.menuCollapsed}
                                onCollapse={this.handleMenuCollapse}>
                                <Menu
                                    selectedKeys={[this.props.location.pathname]}
                                    defaultOpenKeys={categories.map((c) => c.label)}
                                    mode="inline">
                                    {categories.map((cat) => (
                                        <SubMenu
                                            key={cat.label}
                                            title={
                                                <IconMenuItemColumns>
                                                    {cat.iconSrc ? (
                                                        <ImgIcon src={cat.iconSrc} />
                                                    ) : null}
                                                    <BodySmall>{cat.label}</BodySmall>
                                                </IconMenuItemColumns>
                                            }>
                                            {cat.routes
                                                .filter((r) => !r.hideFromMenu)
                                                .map(({ path, label, disabled }) => (
                                                    <Menu.Item key={path} disabled={disabled}>
                                                        <Link to={path}>
                                                            <BodySmall>
                                                                {label}
                                                                {disabled ? (
                                                                    <React.Fragment>
                                                                        {` `}
                                                                        <Tag color="blue">Soon</Tag>
                                                                    </React.Fragment>
                                                                ) : null}
                                                            </BodySmall>
                                                        </Link>
                                                    </Menu.Item>
                                                ))}
                                        </SubMenu>
                                    ))}
                                    <Menu.Item key="/about">
                                        <Link to="/about">
                                            {!this.state.menuCollapsed ? (
                                                <IconMenuItemColumns>
                                                    <ImgIcon src={aboutIcon} />
                                                    <BodySmall>About</BodySmall>
                                                </IconMenuItemColumns>
                                            ) : (
                                                <React.Fragment>
                                                    <ImgIcon src={aboutIcon} />
                                                    <span>About</span>
                                                </React.Fragment>
                                            )}
                                        </Link>
                                    </Menu.Item>
                                </Menu>
                            </LeftSider>
                            <Layout>
                                <Content>
                                    <MaxWidthContent>
                                        {ROUTES.map(
                                            ({ path, component: Component, componentProps }) => (
                                                <Route
                                                    exact
                                                    key={path}
                                                    path={path}
                                                    render={(props: any) => (
                                                        <Component {...props} {...componentProps} />
                                                    )}
                                                />
                                            )
                                        )}
                                    </MaxWidthContent>
                                </Content>
                                <Footer />
                            </Layout>
                        </Layout>
                    </Layout>
                </DefaultAppLayoutProvider>
            </Route>
        );
    }
}

const HeaderColumnsWithSpace = styled(HeaderColumns)`
    padding: 11.5px 0;
`;

const MaxWidthContent = styled(Content)`
    max-width: 57rem;
`;

const Logo = styled.img.attrs({
    src: headerLogo,
    alt: 'Vision Explorer Logo',
    height: '54px',
})``;

const LogoWithText = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.md};
`;

const HeaderLink = styled(Link)`
    &&& {
        &,
        &:hover {
            line-height: 54px;
            text-decoration: none;
            color: unset;
        }
    }
`;
