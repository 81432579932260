import {
    BaseModelParams,
    ImageModelParams,
    ImageGenerationParams,
    VisualQuestionParams,
    VisualQuestionBboxParams,
} from '../components';

interface ModelParams
    extends BaseModelParams,
        ImageModelParams,
        ImageGenerationParams,
        VisualQuestionBboxParams,
        VisualQuestionParams {}

export class BaseQuery {
    constructor(public readonly modelParams: ModelParams) {}

    isValid() {
        return (
            (this.modelParams.label === undefined || this.modelParams.label.length > 0) &&
            (this.modelParams.img1Src === undefined || this.modelParams.img1Src.length > 0) &&
            (this.modelParams.imageName === undefined || this.modelParams.imageName.length > 0) &&
            (this.modelParams.caption === undefined || this.modelParams.caption.length > 0) &&
            (this.modelParams.question === undefined || this.modelParams.question.length > 0) &&
            // this is a hack because we want to make sure that something is set per query,
            // but as of now,, we dont validate tht the right stuff is set per model
            ((this.modelParams.label && this.modelParams.label.length > 0) ||
                (this.modelParams.img1Src && this.modelParams.img1Src.length > 0) ||
                (this.modelParams.imageName && this.modelParams.imageName.length > 0) ||
                (this.modelParams.caption && this.modelParams.caption.length > 0) ||
                (this.modelParams.question && this.modelParams.question.length > 0) ||
                this.modelParams.crop)
        );
    }

    equals(query: { modelParams: ModelParams }): boolean {
        return (
            this.modelParams.label === query.modelParams.label &&
            this.modelParams.img1Src === query.modelParams.img1Src &&
            this.modelParams.imageName === query.modelParams.imageName &&
            this.modelParams.caption === query.modelParams.caption &&
            this.modelParams.question === query.modelParams.question &&
            this.modelParams.crop === query.modelParams.crop
        );
    }

    toJson(): string {
        const modelParamsCopy = JSON.parse(JSON.stringify(this.modelParams));
        modelParamsCopy.hasImage = this.modelParams.image !== undefined;
        if (this.modelParams.crop) {
            if (this.modelParams.cropChanged) {
                // Store the data in un-nested format to be compatible with requests.data
                for (const [key, value] of Object.entries(this.modelParams.crop)) {
                    modelParamsCopy['crop.' + key] = value;
                }
            }
            // Not needed when making API calss
            delete modelParamsCopy.crop;
            delete modelParamsCopy.cropChanged;
        }
        return JSON.stringify(modelParamsCopy);
    }

    toFormData(): FormData {
        const data = new FormData();
        data.append('params', this.toJson());
        if (this.modelParams.image) {
            data.append('image', this.modelParams.image, this.modelParams.image.name);
        }
        return data;
    }
}
