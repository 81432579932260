import * as React from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import { Input } from '@allenai/varnish';

import 'react-image-crop/dist/ReactCrop.css';

import { ModelParamControlProps, ImageModelParams, ModelParamLabel } from './ModelParams';

export const allImageCrop = {
    unit: '%' as '%',
    width: 100,
    height: 100,
};

export interface VisualQuestionBboxParams extends ImageModelParams {
    question?: string;
    crop?: Partial<Crop>;
    cropChanged?: boolean;
}

const initialCropBorder = 8;

type Props = ModelParamControlProps<VisualQuestionBboxParams>;

export class VisualQuestionBboxModelParamControl extends React.Component<
    Props,
    VisualQuestionBboxParams
> {
    private fetching: string;

    constructor(props: Props) {
        super(props);
        this.state = {};
        // Image source we most recently started fetching image-data for
        this.fetching = '';
    }

    static getDerivedStateFromProps(props: Props, state: VisualQuestionBboxParams) {
        if (
            props.modelParams &&
            (props.modelParams.img1Src !== state.img1Src ||
                props.modelParams.question !== state.question ||
                props.modelParams.crop !== state.crop ||
                props.modelParams.cropChanged !== state.cropChanged)
        ) {
            return {
                question: props.modelParams.question,
                img1Src: props.modelParams.img1Src,
                crop: props.modelParams.crop,
                cropChanged: props.modelParams.cropChanged,
            };
        }
        return null;
    }

    componentDidMount() {
        if (this.state.img1Src && this.state.img1Src !== this.fetching) {
            this.fetching = this.state.img1Src;
            this.fetchImage(this.state.img1Src);
        }
    }

    componentDidUpdate(_prevProps: Props, _prevState: VisualQuestionBboxParams) {
        // Based on https://github.com/reactjs/rfcs/issues/26#issuecomment-365744134
        // the check should happen on `componentDidUpdate` and `componentDidMount`
        if (this.state.img1Src && this.state.img1Src !== this.fetching) {
            this.fetching = this.state.img1Src;
            this.fetchImage(this.state.img1Src);
        }
    }

    onQuestionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { modelParams } = this.props;
        modelParams.question = event.target.value;
        this.setState({ question: modelParams.question }, () => this.props.onChange(modelParams));
    };

    onCropChange = (crop: Crop, _percentCrop: Crop) => {
        const { modelParams } = this.props;
        modelParams.crop = crop;
        modelParams.cropChanged = true;
        this.setState({ crop: crop, cropChanged: true }, () => {
            this.props.onChange(modelParams);
        });
    };

    async fetchImage(imgSrc?: string) {
        let s = {
            img1Src: imgSrc,
            imageName: undefined,
            image: undefined,
        };

        if (imgSrc) {
            const response = await fetch(imgSrc);
            const blob = await response.blob();

            const file: any = blob; // convert blob to file
            file.lastModifiedDate = new Date();
            file.name = imgSrc;
            s = {
                img1Src: imgSrc,
                imageName: file.name,
                image: file,
            };
        }

        if (imgSrc === this.fetching) {
            // Images swapped while loading this one, abort
            this.setState(s);
            this.props.onChange(s);
        }
    }

    onLoad = (image: HTMLImageElement) => {
        if (!this.state.cropChanged) {
            // Set the initial crop if the image does not have a starting crop
            // We do this here since we need to know the image size
            const { modelParams } = this.props;
            let crop;
            if (image.height < initialCropBorder * 4 && image.width < initialCropBorder * 4) {
                crop = allImageCrop;
            } else {
                crop = {
                    x: initialCropBorder,
                    y: initialCropBorder,
                    height: image.height - initialCropBorder * 2,
                    width: image.width - initialCropBorder * 2,
                    unit: 'px' as 'px',
                };
            }
            modelParams.crop = crop;
            this.setState({ crop: crop }, () => {
                this.props.onChange(modelParams);
            });
            // ReactCrop requires we return false if the crop was changed
            return false;
        }
    };

    onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const imgSrc = URL.createObjectURL(e.target.files[0]);
            this.fetching = imgSrc;
            const s = {
                img1Src: imgSrc,
                imageName: e.target.files[0].name,
                image: e.target.files[0],
                crop: undefined,
                cropChanged: false,
            };
            this.setState(s);
            this.props.onChange(s);
            // This resets input, if we don't do this the selector well state
            // the filename even if an example is selected from the example lists,
            // TODO a better solution would be to reset the name when that occurs
            e.target.value = '';
        }
    };

    render() {
        const crop = this.state.crop;
        return (
            <>
                {this.props.modelParamComponentUiParams.getInputLabel('img1Src') ? (
                    this.props.modelParamComponentUiParams.getInputLabel('img1Src')
                ) : (
                    <ModelParamLabel>Image:</ModelParamLabel>
                )}
                <div>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={this.onSelectFile}
                        disabled={this.props.disabled}
                    />
                </div>
                <ReactCrop
                    src={this.state.img1Src ? this.state.img1Src : ''}
                    crop={crop || {}}
                    onImageLoaded={this.onLoad}
                    onChange={this.onCropChange}
                    disabled={this.props.disabled}
                />
                <div>
                    {this.props.modelParamComponentUiParams.getInputLabel('question') ? (
                        this.props.modelParamComponentUiParams.getInputLabel('question')
                    ) : (
                        <ModelParamLabel>Question:</ModelParamLabel>
                    )}
                    <Input.TextArea
                        disabled={this.props.disabled}
                        onChange={this.onQuestionChange}
                        value={this.state.question}
                    />
                </div>
            </>
        );
    }
}
