import * as React from 'react';
import styled from 'styled-components';
import { Collapse } from '@allenai/varnish';

import { ImageGenerationAnswer } from '../api';
import { CanvasImage } from './CanvasImage';
import { AnswerInfoProps, ImageArea, PropertiesArea } from '.';

import blank256Src from '../icons/blank_256.png';

type Props = AnswerInfoProps<ImageGenerationAnswer>;

export class ImageGenerationAnswerInfo extends React.Component<Props> {
    canvasSpaceToImageSpace(v: number, scale: number) {
        return Math.floor(v / (scale > 0 ? scale : 1));
    }

    drawAnswer = (
        ctx: CanvasRenderingContext2D,
        scale: number,
        imageGenerationAnswer?: ImageGenerationAnswer
    ) => {
        if (imageGenerationAnswer && imageGenerationAnswer.image.length) {
            const imageData = ctx.getImageData(
                0,
                0,
                ctx.canvas.clientWidth,
                ctx.canvas.clientHeight
            );
            for (let j = 0; j < imageData.width; j++) {
                for (let i = 0; i < imageData.height - 1; i++) {
                    // HACK: initial canvas render adds 1 to y, since there is only 1 render, we remove it here
                    // TODO: consider parameterizing the 1px thing to remove this hack
                    const scaledX = this.canvasSpaceToImageSpace(j, scale);
                    const scaledY = this.canvasSpaceToImageSpace(i, scale);
                    const val = imageGenerationAnswer.image[scaledY][scaledX]; // Y and X are reversed on purpose
                    const index = i * 4 * imageData.width + j * 4;
                    imageData.data[index] = val[0];
                    imageData.data[index + 1] = val[1];
                    imageData.data[index + 2] = val[2];
                    imageData.data[index + 3] = 255;
                }
            }
            ctx.putImageData(imageData, 0, 0);
        }
    };

    render() {
        return (
            <PropertiesArea>
                {this.props.answer ? (
                    <Collapse.Panel header="Generated Image" key="image">
                        <MaxWidthImageArea>
                            <CanvasImage
                                src={blank256Src}
                                draw={
                                    this.props.answer
                                        ? (
                                              ctx: CanvasRenderingContext2D,
                                              padding: number,
                                              scale: number
                                          ) => this.drawAnswer(ctx, scale, this.props.answer)
                                        : undefined
                                }
                            />
                        </MaxWidthImageArea>
                    </Collapse.Panel>
                ) : null}
            </PropertiesArea>
        );
    }
}

const MaxWidthImageArea = styled(ImageArea)`
    max-width: 400px;
`;
