import * as React from 'react';
import { Input } from '@allenai/varnish';

import { ModelParamControlProps, ModelParamLabel, ImageModelParams } from './ModelParams';
import { ImageParamControl } from './';

export interface VisualQuestionParams extends ImageModelParams {
    question?: string;
}

type Props = ModelParamControlProps<VisualQuestionParams>;

export class VisualQuestionModelParamControl extends React.PureComponent<
    Props,
    VisualQuestionParams
> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    static getDerivedStateFromProps(props: Props, state: VisualQuestionParams) {
        if (
            props.modelParams &&
            (props.modelParams.img1Src !== state.img1Src ||
                props.modelParams.question !== state.question)
        ) {
            return {
                question: props.modelParams.question,
                img1Src: props.modelParams.img1Src,
            };
        }
        return null;
    }

    onQuestionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { modelParams } = this.props;
        modelParams.question = event.target.value;
        this.setState({ question: modelParams.question }, () => this.props.onChange(modelParams));
    };

    onImageChange = (newParams: ImageModelParams) => {
        const { modelParams } = this.props;
        this.setState({ ...modelParams, ...newParams }, () =>
            this.props.onChange({ ...modelParams, ...newParams })
        );
    };

    render() {
        return (
            <>
                <ImageParamControl
                    disabled={this.props.disabled}
                    onChange={this.onImageChange}
                    modelParams={{ img1Src: this.state.img1Src }}
                    onLoading={this.props.onLoading}
                    modelParamComponentUiParams={this.props.modelParamComponentUiParams}
                />
                <div>
                    {this.props.modelParamComponentUiParams.getInputLabel('question') ? (
                        this.props.modelParamComponentUiParams.getInputLabel('question')
                    ) : (
                        <ModelParamLabel>Question:</ModelParamLabel>
                    )}
                    <Input.TextArea
                        disabled={this.props.disabled}
                        onChange={this.onQuestionChange}
                        value={this.state.question}
                    />
                </div>
            </>
        );
    }
}
