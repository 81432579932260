import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { Select, Typography, BackTop } from '@allenai/varnish';

import {
    Answer,
    AnswerInfoProps,
    BaseAnswer,
    BaseAnswerView,
    BaseModelParams,
    Markdown,
    MetaTags,
    ModelParamControlProps,
    ModelParamComponentUiParams,
} from '../components';
import { BaseQuery, BaseRequest } from '../api';
import { Dictionary } from '../utils';
import { Attribution } from '../tasks';

const removeMd = require('remove-markdown');

const { BodyJumbo } = Typography;

export interface AnswerPageProps<A extends Answer, P extends BaseModelParams> {
    title: string;
    markdownDescription: string;
    stepOneInstruction?: string;
    examples: P[];
    modelParamComponent:
        | (new (props: ModelParamControlProps<P>) => React.Component<ModelParamControlProps<P>>)
        | React.FunctionComponent<ModelParamControlProps<P>>;
    modelParamComponentUiParams: ModelParamComponentUiParams;
    models: ModelDetails<A>[];
}

interface Props<A extends Answer, P extends BaseModelParams>
    extends AnswerPageProps<A, P>,
        RouteComponentProps {}

interface ModelDetails<A> {
    name: string;
    markdownDescription: string;
    attribution?: Attribution;
    answerInfo: new (props: AnswerInfoProps<A>) => React.Component<AnswerInfoProps<A>>;
    apiCall: BaseRequest<A>;
    estimatedApiDuration: number;
}

interface State {
    query: BaseQuery;
    paramsLoading: boolean;
    modelLoading: boolean;
    modelStates: Dictionary<BaseAnswerView>;
}

export class AnswerPage<A extends Answer, P extends BaseModelParams> extends React.PureComponent<
    Props<A, P>,
    State
> {
    constructor(props: Props<A, P>) {
        super(props);
        this.state = {
            query: new BaseQuery({}), // TODO allow undefined
            paramsLoading: false,
            modelLoading: false,
            modelStates: {},
        };
    }

    handleModelStatusOnChange = (modelName: string, modelState: BaseAnswerView) => {
        // hold on to states of models so we can show progress on loading all models
        const { modelStates } = this.state;
        if (modelState !== BaseAnswerView.LOADING) {
            delete modelStates[modelName];
        } else {
            modelStates[modelName] = modelState;
        }

        this.setState({
            modelStates,
            modelLoading: Object.values(modelStates).length > 0,
        });
    };

    handleExampleChange = (selectedLabel: any) => {
        const selectedExample = this.props.examples.filter((e) => e.label === selectedLabel)[0];
        if (selectedExample) {
            const { modelParams } = this.state.query;
            this.setState({
                query: new BaseQuery({ ...modelParams, ...selectedExample }),
            });
        }
    };

    handleParamsChange = (newParams: P) => {
        const { modelParams } = this.state.query;

        this.setState({
            query: new BaseQuery({ ...modelParams, ...newParams }),
        });
    };

    handleParamsLoadingChange = (paramsLoading: boolean) => {
        this.setState({
            paramsLoading,
        });
    };

    disableInput = () => {
        return this.state.paramsLoading || this.state.modelLoading;
    };

    render() {
        const { modelParamComponent: ModelParamComponent } = this.props;
        return (
            <React.Fragment>
                <BackTop />
                <MetaTags
                    title={this.props.title}
                    description={removeMd(this.props.markdownDescription)}
                />

                <Title>{this.props.title}</Title>
                <Description>
                    <Markdown>{this.props.markdownDescription}</Markdown>
                </Description>

                <h6>Try it for yourself</h6>
                <FirstStep>
                    <BodyJumbo>
                        1.{' '}
                        {this.props.stepOneInstruction
                            ? this.props.stepOneInstruction
                            : 'Upload an Image (or choose one from the examples)'}
                    </BodyJumbo>
                </FirstStep>
                <Form>
                    <FormSelect
                        disabled={this.disableInput()}
                        onChange={this.handleExampleChange}
                        placeholder="Examples...">
                        {this.props.examples.map((example, i) => (
                            <Select.Option key={example.label || i} value={example.label || i}>
                                {example.label || `Option ${i}`}
                            </Select.Option>
                        ))}
                    </FormSelect>

                    <ModelParamComponent
                        modelParams={this.state.query.modelParams as P}
                        disabled={this.disableInput()}
                        onChange={this.handleParamsChange}
                        onLoading={this.handleParamsLoadingChange}
                        modelParamComponentUiParams={this.props.modelParamComponentUiParams}
                    />
                </Form>

                <SecondStep>
                    <BodyJumbo>2. Run a model</BodyJumbo>
                </SecondStep>
                {this.props.models.map((model) => (
                    <BaseAnswer
                        key={model.name}
                        {...model}
                        originalImgSrc={this.state.query.modelParams.img1Src}
                        query={this.state.query}
                        onStatusChange={this.handleModelStatusOnChange}
                    />
                ))}
            </React.Fragment>
        );
    }
}

const Title = styled.h3`
    margin-bottom: ${({ theme }) => theme.spacing.xxs};
`;

const Description = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

const FirstStep = styled.div`
    margin-top: ${({ theme }) => theme.spacing.lg};
`;

const SecondStep = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

const Form = styled.form`
    margin: ${({ theme }) => `0 0 ${theme.spacing.lg}`};
    max-width: 36rem;
`;

const FormSelect = styled(Select)`
    margin: ${({ theme }) => `${theme.spacing.xs} 0 0 0`};
    width: 100%;
`;
