import * as React from 'react';
import styled from 'styled-components';
import { Typography, Table, Collapse, Theme } from '@allenai/varnish';
import { chartingColor, Color } from '@allenai/varnish/es/varnish/colors';
import { BasicFilterDropdown, FilterIcon, ColumnProps } from '@allenai/varnish/es/table';
import { SortOrder } from '@allenai/varnish/es/table/interface';

import { Dictionary, strIncludesCaseInsensitive } from '../utils';
import {
    GroundedSituationRecognitionAnswer,
    GroundedSituation,
    GroundedSituationField,
    Detection,
} from '../api';
import { CanvasImage } from './CanvasImage';
import { AnswerInfoProps, KeyValue, SparkEnvelope, Spark, SparkValue, PropertiesArea } from '.';

const { BodySmallBold, BodySmall } = Typography;

type Props = AnswerInfoProps<GroundedSituationRecognitionAnswer>;
type GroundedSituationItem = KeyValue<GroundedSituation>;

export class GroundedSituationRecognitionAnswerInfo extends React.Component<Props> {
    fontSizeScaleAdjust = 0.8;
    fontPad = 4 * this.fontSizeScaleAdjust;
    lineWidth = 1.5 * this.fontSizeScaleAdjust;
    usedColorindex = 0;
    classDict: { [id: string]: Color } = {};
    pageSize = 6;

    columns: ColumnProps<GroundedSituationItem>[] = [
        {
            title: 'Verb',
            dataIndex: 'key',
            key: 'key',
            width: '15%',
            sorter: (a: GroundedSituationItem, b: GroundedSituationItem) =>
                a.key < b.key ? -1 : 1,
            sortDirections: ['descend' as SortOrder, 'ascend' as SortOrder],
            filterDropdown: BasicFilterDropdown,
            filterIcon: FilterIcon,
            onFilter: (filter, record) => strIncludesCaseInsensitive(filter, record.key),
        },
        {
            title: 'Detections',
            dataIndex: ['value', 'fields'],
            key: 'value.fields',
            width: '35%',
            render: (fields: Dictionary<GroundedSituationField>) => (
                <SmallImageArea>
                    <CanvasImage
                        padding={this.getFontSize() + this.fontPad}
                        src={this.props.originalImgSrc}
                        draw={
                            this.props.answer
                                ? (ctx: CanvasRenderingContext2D, padding: number, scale: number) =>
                                      this.drawAnswer(
                                          ctx,
                                          Object.keys(fields)
                                              .map((k) => {
                                                  return { ...fields[k].detection, class: k };
                                              })
                                              .filter(
                                                  (d) =>
                                                      d.start_x !== undefined &&
                                                      d.end_x !== undefined &&
                                                      d.start_y !== undefined &&
                                                      d.end_y !== undefined
                                              ),
                                          padding,
                                          scale
                                      )
                                : undefined
                        }
                    />
                </SmallImageArea>
            ),
        },
        {
            title: 'Roles',
            dataIndex: ['value', 'fields'],
            key: 'value.fields',
            width: '35%',
            render: (fields: Dictionary<GroundedSituationField>) => (
                <div>
                    {Object.keys(fields).map((k) =>
                        fields[k].value ? (
                            <div key={k}>
                                <BodySmallBold>{k}:</BodySmallBold>{' '}
                                <BodySmall>{fields[k].value}</BodySmall>
                                {fields[k].detection && fields[k].detection.confidence ? (
                                    <BodySmall>
                                        {' '}
                                        ({fields[k].detection.confidence.toFixed(2)})
                                    </BodySmall>
                                ) : null}
                            </div>
                        ) : null
                    )}
                </div>
            ),
            filterDropdown: BasicFilterDropdown,
            filterIcon: FilterIcon,
            onFilter: (filter, record) => {
                return (
                    Object.keys(record.value.fields).filter((f) =>
                        f.toLowerCase().includes(filter.toString().toLowerCase())
                    ).length > 0
                );
            },
        },
        {
            title: 'Score',
            dataIndex: ['value', 'score'],
            key: 'value',
            width: '15%',
            render: (val: number) => (
                <div title={val.toString()}>
                    <SparkEnvelope>
                        <Spark value={val} />
                    </SparkEnvelope>{' '}
                    <SparkValue>{`${(100 * val).toFixed(1)}%`}</SparkValue>
                </div>
            ),
            sorter: (a: GroundedSituationItem, b: GroundedSituationItem) =>
                a.value.score - b.value.score,
            sortDirections: ['descend' as SortOrder, 'ascend' as SortOrder],
            defaultSortOrder: 'descend' as SortOrder,
        },
    ];

    getFontSize() {
        return Math.max(7, Math.min(16, window.innerWidth / 32)) * this.fontSizeScaleAdjust;
    }

    drawAnswer = (
        ctx: CanvasRenderingContext2D,
        detections: Detection[],
        padding: number,
        scale: number
    ) => {
        const fontSize = this.getFontSize();
        ctx.lineWidth = this.lineWidth;
        ctx.font = fontSize + 'px Lato';
        const palette = Object.values(chartingColor);
        detections.forEach((d) => {
            const scaledDims = {
                start_x: scale * d.start_x,
                start_y: scale * d.start_y,
                end_x: scale * d.end_x,
                end_y: scale * d.end_y,
            };

            let color = this.classDict[d.class];
            if (!color) {
                color = palette[this.usedColorindex++ % palette.length];
                this.classDict[d.class] = color;
            }

            ctx.beginPath();
            ctx.strokeStyle = color.hex;
            ctx.rect(
                padding + scaledDims.start_x,
                padding + scaledDims.start_y,
                scaledDims.end_x - scaledDims.start_x,
                scaledDims.end_y - scaledDims.start_y
            );
            ctx.stroke();

            const label = `${d.class} ${d.confidence ? `(${d.confidence.toFixed(2)})` : ''}`;
            ctx.fillStyle = color.hex;
            ctx.fillRect(
                padding + scaledDims.start_x - this.lineWidth / 2,
                padding + scaledDims.start_y - fontSize - this.fontPad,
                Math.max(
                    scaledDims.end_x - scaledDims.start_x + this.lineWidth,
                    ctx.measureText(label).width + this.fontPad * 2
                ),
                fontSize + this.fontPad
            );

            ctx.fillStyle = color.useContrastText
                ? Theme.default.palette.text.contrast.hex
                : Theme.default.palette.text.primary.hex;
            ctx.fillText(
                label,
                padding + scaledDims.start_x + this.fontPad,
                padding + scaledDims.start_y - this.fontPad
            );
        });
    };

    render() {
        const data: GroundedSituationItem[] = this.props.answer
            ? Object.keys(this.props.answer.situations).map((k) => {
                  return {
                      key: k,
                      value: this.props.answer
                          ? this.props.answer.situations[k]
                          : ({} as GroundedSituation),
                  };
              })
            : [];

        return (
            <PropertiesArea>
                {this.props.answer ? (
                    <Collapse.Panel header="Predicted Situations" key="table1">
                        <Table
                            size="small"
                            scroll={{ x: true }}
                            rowKey={(record) => `${record.key}_${record.value}`}
                            columns={this.columns}
                            dataSource={data}
                            pagination={
                                data.length > this.pageSize && {
                                    pageSize: this.pageSize,
                                    simple: true,
                                }
                            }
                        />
                    </Collapse.Panel>
                ) : null}
            </PropertiesArea>
        );
    }
}

const SmallImageArea = styled.div`
    min-width: 185px;
`;
