/**
 * This is the main entry point for the UI. You should not need to make any
 * changes here unless you want to update the theme.
 *
 * @see https://github.com/allenai/varnish
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route } from 'react-router-dom';
import { ThemeProvider } from '@allenai/varnish';
import { ScrollToTopOnPageChange } from '@allenai/varnish-react-router';
import { createBrowserHistory } from 'history';
import { Helmet } from 'react-helmet';
import '@allenai/varnish/dist/varnish.css';

import App from './App';
import { trackPageView } from './ga';

// Initialize google analytics page view tracking
const history = createBrowserHistory();
history.listen((location) => {
    trackPageView(location.pathname);
});

ReactDOM.render(
    <Router history={history}>
        <ScrollToTopOnPageChange />
        <Helmet
            defaultTitle="Computer Vision Explorer"
            titleTemplate="Computer Vision Explorer - %s"
        />
        <ThemeProvider>
            <Route path="/" component={App} />
        </ThemeProvider>
    </Router>,
    document.getElementById('root')
);
