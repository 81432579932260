import * as React from 'react';
import { Table, Collapse } from '@allenai/varnish';
import { BasicFilterDropdown, FilterIcon, ColumnProps } from '@allenai/varnish/es/table';
import { SortOrder } from '@allenai/varnish/es/table/interface';

import { strIncludesCaseInsensitive } from '../utils';
import { CaptioningAnswer, Caption } from '../api';
import {
    AnswerInfoProps,
    ImageArea,
    MediumImage,
    SparkEnvelope,
    Spark,
    SparkValue,
    PropertiesArea,
} from '.';

type Props = AnswerInfoProps<CaptioningAnswer>;

export class CaptioningAnswerInfo extends React.Component<Props> {
    pageSize = 6;

    columns: ColumnProps<Caption>[] = [
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
            render: (val: number) => (
                <div title={val.toString()}>
                    <SparkEnvelope>
                        <Spark value={val} />
                    </SparkEnvelope>{' '}
                    <SparkValue>{`${(100 * val).toFixed(1)}%`}</SparkValue>
                </div>
            ),
            sorter: (a: Caption, b: Caption) => a.score - b.score,
            sortDirections: ['descend' as SortOrder, 'ascend' as SortOrder],
            defaultSortOrder: 'descend' as SortOrder,
        },
        {
            title: 'Caption',
            dataIndex: 'answer',
            key: 'answer',
            sorter: (a: Caption, b: Caption) => (a.answer < b.answer ? -1 : 1),
            sortDirections: ['descend' as SortOrder, 'ascend' as SortOrder],
            filterDropdown: BasicFilterDropdown,
            filterIcon: FilterIcon,
            onFilter: (filter, record) => strIncludesCaseInsensitive(filter, record.answer),
        },
    ];

    render() {
        const data: Caption[] = this.props.answer ? this.props.answer.captions : [];

        return (
            <PropertiesArea>
                {this.props.originalImgSrc ? (
                    <Collapse.Panel header="Image" key="image">
                        <ImageArea>
                            <MediumImage src={this.props.originalImgSrc} />
                        </ImageArea>
                    </Collapse.Panel>
                ) : null}
                {this.props.answer ? (
                    <Collapse.Panel header="Predicted Captions" key="table1">
                        <Table
                            size="small"
                            scroll={{ x: true }}
                            rowKey={(record: Caption) => `${record.answer}_${record.score}`}
                            columns={this.columns}
                            dataSource={data}
                            pagination={
                                data.length > this.pageSize && {
                                    pageSize: this.pageSize,
                                    simple: true,
                                }
                            }
                        />
                    </Collapse.Panel>
                ) : null}
            </PropertiesArea>
        );
    }
}
