// NOTE: only pose has been tested...

import { Answer } from '../components';

export interface Person {
    face_keypoints_2d: number[]; // eslint-disable-line camelcase
    hand_left_keypoints_2d: number[]; // eslint-disable-line camelcase
    hand_right_keypoints_2d: number[]; // eslint-disable-line camelcase
    pose_keypoints_2d: number[]; // eslint-disable-line camelcase
}

export interface PoseEstimationAnswer extends Answer {
    people: Person[];
}

export interface DrawablePoint {
    x: number;
    y: number;
    confidence: number;
    color: string;
}

export interface AnnotatedDrawablePoint extends DrawablePoint {
    personId: string;
    partId: string;
    index: string;
    color: string;
}

export interface DrawableLine {
    start: DrawablePoint;
    end: DrawablePoint;
}

export interface DrawablePerson {
    facePoints: DrawablePoint[];
    handLeftPoints: DrawablePoint[];
    handRightPoints: DrawablePoint[];
    posePoints: DrawablePoint[];

    poseLines: DrawableLine[];
    faceLines: DrawableLine[];
    handLeftLines: DrawableLine[];
    handRightLines: DrawableLine[];
}

// convert array of numbers into points by taking 3 at a time
function convertKeypointsToDrawable(arr: number[], colors: string[]): DrawablePoint[] {
    const jump = 3;
    const ret = [];
    for (let i = 0; i < arr.length / jump; i++) {
        ret.push({
            x: arr[jump * i],
            y: arr[jump * i + 1],
            confidence: arr[jump * i + 2],
            color: colors[i % colors.length],
        });
    }
    return ret;
}

// convert array of indicies into lines by taking 2 at a time
function convertPointsToLines(arr: number[], points: DrawablePoint[]): DrawableLine[] {
    const jump = 2;
    const ret = [];
    for (let i = 0; i < arr.length / jump; i++) {
        const l = {
            start: points[arr[jump * i]],
            end: points[arr[jump * i + 1]],
        };
        if ((l.start.x || l.start.y) && (l.end.x || l.end.y)) {
            // dont add lines for points that are 00
            ret.push({
                start: points[arr[jump * i]],
                end: points[arr[jump * i + 1]],
            });
        }
    }
    return ret;
}

// convert array of numbers into colors by taking 3 at a time
function convertToColor(arr: number[]): string[] {
    const jump = 3;
    const ret = [];
    for (let i = 0; i < arr.length / jump; i++) {
        ret.push(`rgb(${arr[jump * i]},${arr[jump * i + 1]},${arr[jump * i + 2]})`);
    }
    return ret;
}

// https://github.com/CMU-Perceptual-Computing-Lab/openpose/blob/master/include/openpose/pose/poseParametersRender.hpp#L19
const poseColors = convertToColor([
    255, 0, 85, 255, 0, 0, 255, 85, 0, 255, 170, 0, 255, 255, 0, 170, 255, 0, 85, 255, 0, 0, 255, 0,
    255, 0, 0, 0, 255, 85, 0, 255, 170, 0, 255, 255, 0, 170, 255, 0, 85, 255, 0, 0, 255, 255, 0,
    170, 170, 0, 255, 255, 0, 255, 85, 0, 255, 0, 0, 255, 0, 0, 255, 0, 0, 255, 0, 255, 255, 0, 255,
    255, 0, 255, 255,
]);

// https://github.com/CMU-Perceptual-Computing-Lab/openpose/blob/0a7d5b8e22db0b26b14ca3f3f8060299d3ee967d/include/openpose/face/faceParameters.hpp#L19
const faceColors = convertToColor([
    255,
    255,
    255, // all same color
]);

// https://github.com/CMU-Perceptual-Computing-Lab/openpose/blob/0a7d5b8e22db0b26b14ca3f3f8060299d3ee967d/include/openpose/hand/handParameters.hpp#L16
const handColors = convertToColor([
    100, 100, 100, 100, 0, 0, 150, 0, 0, 200, 0, 0, 255, 0, 0, 100, 100, 0, 150, 150, 0, 200, 200,
    0, 255, 255, 0, 0, 100, 50, 0, 150, 75, 0, 200, 100, 0, 255, 125, 0, 50, 100, 0, 75, 150, 0,
    100, 200, 0, 125, 255, 100, 0, 100, 150, 0, 150, 200, 0, 200, 255, 0, 255,
]);

export function convertPersonToDrawable(person: Person): DrawablePerson {
    // https://github.com/CMU-Perceptual-Computing-Lab/openpose/blob/master/include/openpose/pose/poseParametersRender.hpp#L17
    const poseIndicies = [
        1, 8, 1, 2, 1, 5, 2, 3, 3, 4, 5, 6, 6, 7, 8, 9, 9, 10, 10, 11, 8, 12, 12, 13, 13, 14, 1, 0,
        0, 15, 15, 17, 0, 16, 16, 18, 14, 19, 19, 20, 14, 21, 11, 22, 22, 23, 11, 24,
    ];
    // https://github.com/CMU-Perceptual-Computing-Lab/openpose/blob/0a7d5b8e22db0b26b14ca3f3f8060299d3ee967d/include/openpose/face/faceParameters.hpp#L12
    const faceIndicies = [
        0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10, 11, 11, 12, 12, 13, 13, 14,
        14, 15, 15, 16, 17, 18, 18, 19, 19, 20, 20, 21, 22, 23, 23, 24, 24, 25, 25, 26, 27, 28, 28,
        29, 29, 30, 31, 32, 32, 33, 33, 34, 34, 35, 36, 37, 37, 38, 38, 39, 39, 40, 40, 41, 41, 36,
        42, 43, 43, 44, 44, 45, 45, 46, 46, 47, 47, 42, 48, 49, 49, 50, 50, 51, 51, 52, 52, 53, 53,
        54, 54, 55, 55, 56, 56, 57, 57, 58, 58, 59, 59, 48, 60, 61, 61, 62, 62, 63, 63, 64, 64, 65,
        65, 66, 66, 67, 67, 60,
    ];
    // https://github.com/CMU-Perceptual-Computing-Lab/openpose/blob/0a7d5b8e22db0b26b14ca3f3f8060299d3ee967d/include/openpose/hand/handParameters.hpp#L13
    const handIndicies = [
        0, 1, 1, 2, 2, 3, 3, 4, 0, 5, 5, 6, 6, 7, 7, 8, 0, 9, 9, 10, 10, 11, 11, 12, 0, 13, 13, 14,
        14, 15, 15, 16, 0, 17, 17, 18, 18, 19, 19, 20,
    ];
    const validPosePointCount = 25 * 3;
    const validFacePointCount = 68 * 3;
    const validHandPointCount = 21 * 3;

    // for testing
    // person.pose_keypoints_2d = [464.089,93.1547,0.930151,488.757,176.684,0.91589,420.761,173.529,0.872125,386.77,278.637,0.902189,433.184,192.208,0.868165,556.747,176.686,0.876816,624.861,266.398,0.9233,603.27,346.693,0.90845,482.568,383.754,0.826864,433.242,383.811,0.795062,454.731,532.183,0.861101,473.413,661.982,0.77608,528.997,383.785,0.812022,501.188,529.044,0.87633,504.38,665.033,0.549391,454.772,84.024,0.976997,479.565,80.7512,0.960321,0,0,0,516.602,90.0882,0.910339,488.784,683.607,0.185971,501.166,683.666,0.228814,507.451,683.572,0.300019,460.981,683.606,0.298868,451.8,683.587,0.316575,476.515,683.612,0.550726];
    // or
    // person.pose_keypoints_2d =[250.749,105.547,0.936968,250.87,182.713,0.933771,182.748,185.828,0.906968,148.853,284.755,0.871765,127.288,374.437,0.899768,318.836,179.747,0.887361,349.679,263.213,0.896157,275.604,213.771,0.858456,247.727,377.462,0.792486,201.474,374.473,0.793688,201.429,519.797,0.872747,185.843,683.68,0.323345,291.07,377.606,0.77109,297.234,532.011,0.891512,287.85,683.551,0.808781,232.359,93.1516,0.94114,263.166,90.1608,0.940635,213.668,108.675,0.917655,284.745,108.604,0.91529,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,185.997,683.715,0.122337];

    const poseValid =
        person.pose_keypoints_2d && person.pose_keypoints_2d.length === validPosePointCount;
    const faceValid =
        person.face_keypoints_2d && person.face_keypoints_2d.length === validFacePointCount;
    const handLeftValid =
        person.hand_left_keypoints_2d &&
        person.hand_left_keypoints_2d.length === validHandPointCount;
    const handRightValid =
        person.hand_right_keypoints_2d &&
        person.hand_right_keypoints_2d.length === validHandPointCount;

    const posePoints = poseValid
        ? convertKeypointsToDrawable(person.pose_keypoints_2d, poseColors)
        : [];
    const facePoints = faceValid
        ? convertKeypointsToDrawable(person.face_keypoints_2d, faceColors)
        : [];
    const handLeftPoints = handLeftValid
        ? convertKeypointsToDrawable(person.hand_left_keypoints_2d, handColors)
        : [];
    const handRightPoints = handRightValid
        ? convertKeypointsToDrawable(person.hand_right_keypoints_2d, handColors)
        : [];

    const poseLines = poseValid ? convertPointsToLines(poseIndicies, posePoints) : [];
    const faceLines = faceValid ? convertPointsToLines(faceIndicies, facePoints) : [];
    const handLeftLines = handLeftValid ? convertPointsToLines(handIndicies, handLeftPoints) : [];
    const handRightLines = handRightValid
        ? convertPointsToLines(handIndicies, handRightPoints)
        : [];

    return {
        facePoints,
        handLeftPoints,
        handRightPoints,
        posePoints,
        poseLines,
        faceLines,
        handLeftLines,
        handRightLines,
    };
}

// https://github.com/CMU-Perceptual-Computing-Lab/openpose/blob/master/doc/output.md#keypoint-ordering
const poseIdLabels = [
    'Nose',
    'Neck',
    'Right Shoulder',
    'Right Elbow',
    'Right Wrist',
    'Left Shoulder',
    'Left Elbow',
    'Left Wrist',
    'Mid Hip',
    'Right Hip',
    'Right Knee',
    'Right Ankle',
    'Left Hip',
    'Left Knee',
    'Left Ankle',
    'Right Eye',
    'Left Eye',
    'Right Ear',
    'Left Ear',
    'Left Big Toe',
    'Left Small Toe',
    'Left Heel',
    'Right Big Toe',
    'Right Small Toe',
    'Right Heel',
];

export function getAnnotatedPoints(
    person: DrawablePerson,
    personId: string
): AnnotatedDrawablePoint[] {
    return [
        ...person.posePoints
            .filter((p) => p.x || p.y)
            .map((p, i) => {
                return {
                    ...p,
                    personId,
                    partId: 'Pose',
                    index: poseIdLabels[i],
                    color: poseColors[i],
                };
            }),
        ...person.facePoints
            .filter((p) => p.x || p.y)
            .map((p, i) => {
                return {
                    ...p,
                    personId,
                    partId: 'Face',
                    index: i.toString(),
                    color: faceColors[i],
                };
            }),
        ...person.handLeftPoints
            .filter((p) => p.x || p.y)
            .map((p, i) => {
                return {
                    ...p,
                    personId,
                    partId: 'Left Hand',
                    index: i.toString(),
                    color: handColors[i],
                };
            }),
        ...person.handRightPoints
            .filter((p) => p.x || p.y)
            .map((p, i) => {
                return {
                    ...p,
                    personId,
                    partId: 'Right Hand',
                    index: i.toString(),
                    color: handColors[i],
                };
            }),
    ];
}

export function getLines(person: DrawablePerson): DrawableLine[] {
    return [
        ...person.poseLines,
        ...person.faceLines,
        ...person.handLeftLines,
        ...person.handRightLines,
    ];
}
