import * as React from 'react';
import styled from 'styled-components';
import { Progress } from '@allenai/varnish';

import { Animator } from '../utils';

interface Props {
    startValue: number;
    endValue: number;
    duration: number;
}

interface State {
    progress: number;
}

export class AnimatedLoading extends React.Component<Props, State> {
    animator: Animator;

    constructor(props: Props) {
        super(props);

        this.animator = new Animator({
            startValue: Math.max(0, this.props.startValue),
            endValue: Math.min(100, this.props.endValue),
            duration: this.props.duration,
            framesPerSec: 4,
            onChange: (value: number) => {
                this.setState({ progress: value });
            },
        });

        this.state = {
            progress: 0,
        };
    }

    componentDidMount() {
        this.animator.start();
    }

    render() {
        return <WideProgress percent={this.state.progress} status="active" showInfo={false} />;
    }
}

const WideProgress = styled(Progress)`
    width: ${({ theme }) => `calc(100% - ${theme.spacing.md} - ${theme.spacing.md})`};
    margin: ${({ theme }) => `56px ${theme.spacing.lg} 0 ${theme.spacing.lg}`};
`;
