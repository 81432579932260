// This AnswerInfo can be used to quickly mock out a new model

import * as React from 'react';

import { AnswerInfoProps, ImageArea, MediumImage } from '.';

type Props = AnswerInfoProps<any>;

export class JsonAnswerInfo extends React.Component<Props> {
    render() {
        return (
            <React.Fragment>
                {this.props.originalImgSrc ? (
                    <ImageArea>
                        <MediumImage src={this.props.originalImgSrc} />
                    </ImageArea>
                ) : null}
                {this.props.answer ? <div>{JSON.stringify(this.props.answer, null, 2)}</div> : null}
            </React.Fragment>
        );
    }
}
