import styled from 'styled-components';

export interface AnswerInfoProps<T extends Answer> {
    answer?: T;
    originalImgSrc?: string;
}

export interface Answer {
    error?: string;
}

export const ImageArea = styled.div``;

export const MediumImage = styled.img`
    width: 100%;
    max-width: 30rem;
    max-height: 30rem;
    object-fit: contain;
    object-position: 0 0;
`;

export interface KeyValue<T> {
    key: string;
    value: T;
}
